import './styles.scss';
import React, {useContext} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {IFileAttributes, IViewerRecordContext, ViewerRecordContext} from "../../../types/document";

/* Components */
import ViewerDocument from "../../../components/viewer/viewer-document";
import ViewerToolbar from "../../../components/viewer/viewer-toolbar";
import ViewerMetadata from "../../../components/viewer/viewer-metadata";
import ViewerRecords from "../../../components/viewer/viewer-records";
import ViewerCite from "../../../components/viewer/viewer-cite";
import ViewerTagsNotes from "../../../components/viewer/viewer-save";
import ViewerDownload from "../../../components/viewer/viewer-download";
import ViewerShare from "../../../components/viewer/viewer-share";
import ViewerTools from "../../../components/viewer/viewer-tools";

interface IProps {
  fileAttributes: IFileAttributes;
  activeRecordTool: string | null;
  handleToolSelect(recordTool: string): void;
}

const ViewerMobile = (props: IProps):JSX.Element => {
  const {
    fileAttributes,
    handleToolSelect
  } = props;

  const activeRecordTool = (props.activeRecordTool === Constants.RECORDS) ? Constants.SEARCH_RESULTS : props.activeRecordTool;

  /* context */
  const viewerRecord: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;

  const toolPanel = ():JSX.Element => {
    let tool: JSX.Element;
    switch (activeRecordTool) {

      case Constants.METADATA:
        tool = <ViewerMetadata/>
        break;

      case Constants.SEARCH_RESULTS:
      case Constants.BROWSE:
      case Constants.BATES:
      case Constants.MORE_LIKE_THIS:
      case Constants.POSSIBLE_DUPLICATES:
        tool = <ViewerRecords
          activeTool={activeRecordTool}
          handleToolSelect={handleToolSelect}
        />
        break;

      case Constants.CITE:
        tool = <ViewerCite/>
        break;

      case Constants.TAGS_NOTES: /* todo: jain - add to library ?*/
        tool = <ViewerTagsNotes/>
        break;

      case Constants.DOWNLOAD:
        tool = <ViewerDownload/>
        break;

      case Constants.SHARE:
        tool = <ViewerShare/>
        break;

      case Constants.DOCUMENT:
        tool = <>
          <ViewerToolbar
            fileType={fileAttributes?.type}
            activeRecordTool={activeRecordTool}
          />
          <ViewerDocument
            viewerRecord={viewerRecord}
            fileAttributes={fileAttributes}/>
        </>
        break;

      default:
        tool = <></>;
    }
    return tool;
  }


  return (
    <div className={'viewer-mobile'}>
      {!activeRecordTool && <ViewerTools
          fileType={fileAttributes?.type}
          activeRecordTool={activeRecordTool}
          setActiveTool={handleToolSelect}/>}

      {activeRecordTool && toolPanel()}
    </div>
  )
}

export default ViewerMobile;
