import './styles.scss';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

interface IProps  {
  label: string;
  clickFunc?: any;
  link?: string;
  icon?:string;
  disabled?:boolean;
}

const TextLink = (props: IProps):JSX.Element => {
  const {
    clickFunc,
    link,
    label,
    icon,
    disabled
  } = props;

  const [iconClasses, setIconClasses] = useState(null);
  const navigate = useNavigate();

  useEffect(()=>{
    if (!icon) return;

    const bsClass = (): string | null => {
      switch (icon) {
        case 'external':
          return ' ms-2 bi bi-box-arrow-up-right'

        case 'pdf':
          return ' ms-1 bi bi-file-earmark-pdf';

        case 'doc':
          return ' ms-1 bi bi-file-earmark';

        default:
          return null
      }
    }
    setIconClasses( bsClass() )
  }, [icon])

  const handleClick = ()=> {
    if (disabled) return;
    if (clickFunc) {
      clickFunc()
    } else if (link) {
      console.log('link ' + link)

      if (link.indexOf('http') !== -1 ) {
        // external
        window.location.href = link;
      } else {
        // internal
        navigate(link);
      }
    }
  }

  return (
    <button
      disabled = {disabled}
      className={'text-link btn' + (disabled ? ' disabled': '')}
      onClick={() => handleClick()}>
      <div>
        <span className={'label'}>{label}</span>
        {iconClasses && <i className={'text-larger me-1' + iconClasses}></i>}
      </div>
       <div className={'btn-underline'}/>
    </button>
  )
}

export default TextLink;
