import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

/* Data */
import SEARCH_META_DATA from "../../../assets/data/search.meta.json";

/* Types, Constants, Utils */
import Utils from "../../../utils";
import {RecordsContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Bootstrap */
import {Card, Col, Collapse, Container, Row} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {ISearchState} from "../../../types/redux/search";
import {
  addToSelectedResults,
  removeFromSelectedResults,
  setInitialSelection,
  setSelectedResults
} from "../../../redux/slices/result-slice";
import {IUserState} from "../../../types/redux/user";

/* Hooks */
import {useSearchParams} from "../../../hooks";

/* Components */
import MediaPreview from "../media-preview";
import Tags from "../../tags-notes/tags/tags";
import CardHeader from '../card-header';
import TagsModal from "../../tags-notes/tags/tag-modal";
import Note from "../../tags-notes/notes";
import {ISavedState, TSavedDocument} from "../../../types/redux/saved";
import useCardField, {IUseCardField} from "../hooks/useCardField";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import LinkButton from "../../button-link";


interface IProps  {
  record: any;
  index: number;
}

const DocumentCard = (props: IProps):JSX.Element => {
  const {
    record,
    index
  } = props;

  /* redux */
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  /* state */
  const [expanded, setExpanded] = useState<boolean>(resultsState.expand_all);
  const [selected, setSelected] = useState<boolean>(false);
  const [savedDocumentData, setSavedDocumentData] = useState<TSavedDocument | null>(null);

  /* hooks */
  const {currentSearchParams} = useSearchParams();
  const {getFieldContent}: IUseCardField = useCardField();

  /* const */
  const excerpt: string | undefined = record.highlighting && record.highlighting.ot ? Utils.removeTags(record.highlighting.ot.join(', ')).replace(/�|�/g, "") : undefined;

  useEffect(()=>{
    /* toolbar select all and expand all*/
    setExpanded(resultsState.expand_all);
    setSelected(resultsState.selected.findIndex(result => result.id === record.id) !== -1);
  }, [resultsState.expand_all, resultsState.selected, record.id])

  useEffect(()=>{
    const savedDocument: TSavedDocument | undefined = savedState.documents.find((document: TSavedDocument) => document.documentId === record.id);
    setSavedDocumentData(savedDocument ? savedDocument : null)
  }, [record, savedState.documents])

  const handleLinkClick = (): void =>{
    dispatch(setInitialSelection(record.id));
    dispatch(setSelectedResults([record.id]))
  }

  const getLabel = (): JSX.Element => {
    let title: string;
    if (record.highlighting && record.highlighting.ti) {
      title = record.highlighting.ti;
    } else {
      title = record.ti ? record.ti : 'Unknown Title';
    }

    const resultNumber: number = (searchState.current_page - 1) * searchState.results_per_page + index + 1;
    return (
      <Link
        className={"header-link small text-black"}
        to={'/' + Utils.convertToKeyOrId(record.industry[0]) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString()}
        onClick={()=>handleLinkClick()}
      >
        <span>{resultNumber}. </span>
        {getFieldContent(title)}
      </Link>
    )
  }

  const entry = (label: string, value: any): JSX.Element => {
    if (value) {
      return <>
        <Col lg={2} className={'text-normal'}><b>{label}:</b></Col>
        <Col lg={10}  className={'text-normal'}>{getFieldContent(value)}</Col>
      </>
    }
    return <></>
  }

  const handleSelected = (selected: boolean): void => {
    setSelected(selected);
    if (selected) {
      dispatch(addToSelectedResults([record.id]))
    } else {
      dispatch(removeFromSelectedResults([record.id]))
    }
  }

  const fields = (loc: string):JSX.Element[] =>{
    const resultFields: any[] = SEARCH_META_DATA.resultFields;
    const entries: JSX.Element[] = [];
    resultFields.forEach((field: any) =>  {
      for (const fieldId in field) {
        const fieldValues: string[] = field[fieldId];
        if (fieldValues && (fieldValues[0] === Constants.ALL)) {
          if (((loc === Constants.ABOVE) && (fieldValues.indexOf(Constants.ABOVE) !== -1)) ||
              ((loc === Constants.BELOW) && (fieldValues.indexOf(Constants.ABOVE) === -1)) ) {
            /* @ts-ignore*/
            const mappedValues: string[] = SEARCH_META_DATA.allFields[fieldId];
            switch (fieldId) {
              case 'ark':
                entries.push(entry('ARK', "ark:/88122/" + record.id))
                break;

              case 'cited':
                if (record['journal']) {
                  let journal: any[] = JSON.parse(record['journal']);
                  entries.push(entry(mappedValues[0], <ol className={'ps-0'}>
                    {journal.map((entry:any, index:number) => {
                      let url: string = window.location.protocol + '//' + window.location.host + '/industries?industries=all-industries&db-set=bibliography&npp=20&q=null,all,contains,refid:' + entry.id + '&sort=relevance&pg=1';
                      return <li className={'p-0 m-0 cited'} key={'jrn'+index}>
                        <a href={url} target={'_blank'} rel="noreferrer"><i>{getFieldContent(entry.title)}</i></a>
                      </li>
                    })}
                  </ol>))
                }
                break;

              default:
                entries.push(entry(mappedValues[0], record[fieldId]))
            }
          }
        }
      }
    })
    return entries
  }

  const handleViewDocumentClick = (): void => {
    dispatch(setInitialSelection(record.id));
    dispatch(setSelectedResults([record.id]));
    const url: string = '/' + Utils.convertToKeyOrId(record.industry) + '/documents/' + Constants.VIEWER + '?' + Constants.INITIAL_ID + '=' + record.id + '&' + Constants.ID + '=' + record.id + '&' + currentSearchParams().toString();
    navigate(url);
  }

  const toggleExpanded = ():void =>{
    setExpanded(!expanded)
  }

  return (
    <RecordsContext.Provider value={{records:[record]}}>
      <Card className={"document-card m-0 mb-3 p-0 pb-3"}>
        <CardHeader
          selected={selected}
          handleSelected={handleSelected}
          getLabel={getLabel}
        />

        <Card.Body>
          <Container className={'px-2 text-normal mt-4'}>
            <Row className={'mb-0'}>
              <Col xs={10} >
                <Row className={'doc-content mb-0 position-relative'}>
                  {fields(Constants.ABOVE).map((elem: JSX.Element, index: any) => <Row className={'entry-row d-flex'} key={index}>{elem}</Row>)}
                </Row>

                <Collapse in={expanded}>
                  <div className={'doc-content mb-3'}>
                    <Row>
                      {entry('Excerpt', excerpt)}
                    </Row>

                    <Row>
                      {/*{entry('Original Format', record.original format)}*/}
                      {fields(Constants.BELOW).map((elem: JSX.Element, index: any) => <Row className={'entry-row d-flex'} key={index}>{elem}</Row>)}
                    </Row>
                  </div>
                </Collapse>

                {/* Tags modal */}
                <Row className={'doc-tags'}>
                  <Col>
                    <span className={'me-4'}><b>Tags:</b></span>
                    <TagsModal appliedTags={savedDocumentData?.tags}/>
                  </Col>
                </Row>

                {/* Tags display */}
                <Row>
                  <Col className={'ms-5 ps-3 mx-0 px-0'}>
                    <Tags appliedTags={savedDocumentData?.tags}/>
                  </Col>
                </Row>

                {/* Note */}
                {userState.loggedIn &&
                  <Row className={'doc-note doc-content mt-3 mx-0 px-0'}>
                    <Col className={'text-nowrap'}>
                      <span className={'me-3'}><b>Notes:</b></span>
                      <Note context={Constants.DOCUMENT}/>
                    </Col>
                  </Row>}

                <Row className={'expand'}>
                  <Col className={'my-3 mx-0 px-0'} >
                    <LinkButton
                      label={expanded ? 'Collapse' : 'Expand'}
                      underline={true}
                      clickFunc={toggleExpanded}
                    />
                  </Col>
                </Row>
              </Col>

              {/* Preview Image */}
              <Col lg={2} className={'d-none d-lg-block h-100 center'}>
                <MediaPreview context={Constants.SEARCH}/>
              </Col>

            </Row>

            <Row className={'doc-view'}>
              <Col xs={12} className={'text-end'}>
                <LinkButton
                  label= "View Document"
                  clickFunc={handleViewDocumentClick}
                  linkArrow={true}
                  underline={true}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </RecordsContext.Provider>
  )
}

export default DocumentCard;
