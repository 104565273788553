import './styles.scss';
import React from "react";

/* redux */
import PostLayout from "../../payload-web/PostLayout";
import Layout from "../../payload-web/Layout";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";
import LinkButton from "../../../../../components/button-link";

interface IProps {
  post: any,
  index: number
}

const NewsPost = (props: IProps):JSX.Element => {
  const {
    post,
    index
  } = props;

  /* hooks */
  const navigate:NavigateFunction = useNavigate();

  return (
    <div className={'post-wrapper'} key={'ne' + index}>

      {/* post entry  */}
      <div key={'ne' + index}>
        {post && <div className={'post pb-1'} key={'li' + index}>
          <PostLayout post={post}/>
          <Layout layout={post.layout}/>
        </div>}

        {/* View all button */}
        <div>
          <div className={'fade-out'}/>

          <div className={'btn-container d-flex pt-2'}>
            <LinkButton
              label= "View Post"
              clickFunc={()=>navigate('/news/'+ post.id)}
              linkArrow={true}
              underline={true}
            />
          </div>
        </div>

      </div>

      {/* Horizontal Rule */}
      <hr/>
    </div>
  )
}
export default NewsPost;
