import * as Constants from "../constants";
import Utils from "./index";
import {IFileAttributes} from "../types/document";

const DocumentUtils = {
  getFileAttributes:  (document: any): IFileAttributes => {
    let type: string | undefined = undefined;
    let size: string | undefined = undefined;

    if (!document.availability) {
      return {
        type: 'null',
        size: '0'
      }
    }


    /* todo: jain - this should be based on availability status field / facet - ticket number - OIDA 244
    *   "availabilitystatus_facet":["no restrictions"],
	  *     "no restrictions",19457876,
    *     "confidential",1411518,
    *    "formerly confidential",1110119,
    *    "privileged",374708,
    *    "formerly privileged",129900,
    *    "copyright",94,
    *    "deaccessioned",1]},
    */

    /* initially verify available to public */
    if (document.availability.indexOf(Constants.CONFIDENTIAL) > -1) {
      type = Constants.CONFIDENTIAL;
    } else if (document.availability.indexOf(Constants.PRIVILEGED) > -1) {
      type = Constants.PRIVILEGED;
    } else if (document.availability.indexOf(Constants.COPYRIGHT) > -1) {
      type = Constants.COPYRIGHT;
    } else {
      /* check for type */
      let artifacts: Array<any> = [];
      if (document.artifact) {
        artifacts = JSON.parse(document.artifact);
      }

      if (artifacts.length) {
        /* pdf or zip */
        let mediaType: string;
        for (let i = 0; i < artifacts.length; i++) {
          mediaType = artifacts[i].mediaType.split('/').slice(-1).pop();
          if ((mediaType === Constants.ZIP) || (mediaType === Constants.PDF)) {
            type = mediaType;
            size = Utils.convertBytes(artifacts[i].size);
            if (mediaType === Constants.ZIP) {
              break; // if native overwrite pdf type and exit
            }
          }
        }
      }

      /* not pdf or zip */
      if (!type) {
        if (document.access) {
          /* video or audio or website */
          const access: any = JSON.parse(document.access);
          type = access.type;
          size = artifacts[0] ? Utils.convertBytes(artifacts[0].size) : undefined;
        }
      }
    }

    return {
      type: type,
      size: size,
    };
  },

  getPublicVersions:  (document: any): any[] => {
    const publicVersions: any[] = [];
    if (document.moreLikeThis) {
      const docs: any[] = document.moreLikeThis[document.id].docs;
      for (let i=0; i<docs.length; i++) {
        if (docs[i].availability.indexOf(Constants.PUBLIC) !== -1){
          publicVersions.push(docs[i])
        }
      }
    }
    return publicVersions
  },

  getOCRText:  (document: any): any[] => {
    const publicVersions: any[] = [];
    if (document.moreLikeThis) {
      const docs: any[] = document.moreLikeThis[document.id].docs;
      for (let i=0; i<docs.length; i++) {
        if (docs[i].availability.indexOf(Constants.PUBLIC) !== -1){
          publicVersions.push(docs[i])
        }
      }
    }
    return publicVersions
  }
};

export default DocumentUtils;
