import './styles.scss';
import React, {Dispatch, useEffect,  useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap*/
import {Button, ButtonGroup, Form} from "react-bootstrap";

/* React */
import {INotification, INotificationItem, ISettings, IUserState} from "../../../../types/redux/user";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {addNotification, removeNotifications, updateSettings} from "../../../../redux/slices/user-slice";
import SortDropdown from "../../../../components/toolbars/components/sort-dropdown";
import ResultsPerPageDropdown from "../../../../components/toolbars/components/results-per-page-dropdown";
import NotificationService from "../../../../api/notifications";
import {addToast} from "../../../../redux/slices/toast-slice";
import {IIndustryMenuItem} from "../../../../components/industry-menu/interface";
import Switch from '../../../../components/switch';

const SettingsGeneral = ():JSX.Element => {
  const settings: ISettings = useSelector<RootState, IUserState>(state => state.user).settings;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const [allNotifications, setAllNotifications] = useState<INotificationItem[]>([]);

  useEffect(()=>{
    if (userState.loggedIn) {
      const _allNotifications: any = Constants.INDUSTRY_ITEMS.map((item: IIndustryMenuItem) => {
        const selected: INotification = userState.notifications.find((n:any)=> n.targetCode.toLowerCase() === item.industry)
        return {
          active: !!selected,
          label: item.label,
          targetCode: item.industry,
          type: Constants.INDUSTRY
        }
      })

      const index: number = _allNotifications.findIndex((n:any)=>n.targetCode === Constants.ALL_INDUSTRIES)
      _allNotifications.splice(index, 1);

      setAllNotifications(_allNotifications);
   }
  }, [userState.loggedIn, userState.notifications])

  const toggleButton = (setting: string, value: boolean): JSX.Element =>{
    return <Button
      className={!value ? 'bg-white text-primary' : ''}
      onClick={()=>dispatch(updateSettings({...settings, ...{search: setting}}))}
    >
      {value &&
        <i className={'bi bi-check h4 position-absolute'}/>}
      <span className={(value ? 'ms-3' : '') + ' px-3'}>{Utils.toTitleCase(setting, true)}</span>
    </Button>
  }

  const handleToggle = (id:string): void => {
    const settingsCln: ISettings = {...settings};
    const searchResultsCln: any = {...settingsCln.search_results};
    searchResultsCln[id] = !searchResultsCln[id];
    settingsCln.search_results = searchResultsCln;
    dispatch(updateSettings(settingsCln))
  }

  const handleNotificationToggle = (id: string)=> {
    const notificationItem:INotificationItem = allNotifications.find(n=>n.targetCode === id);
    if (notificationItem.active) {
      const notificationId: number = userState.notifications.find(n => {
        return n.targetCode.toLowerCase() === notificationItem.targetCode.toLowerCase()
      }).id;
      const params: any = {
        notificationRequestIds: [notificationId].toString()
      }
      const msg: string = Utils.toTitleCase(notificationItem.targetCode) + ' notification deactivate.';
      NotificationService.deleteNotifications(params).then(response => {
        dispatch(removeNotifications([notificationId]));
        dispatch(addToast({
          bg: 'bg-success',
          msg: msg,
          id: -1
        }))
      }).catch(e => {
        addToast({
          bg: 'bg-danger',
          msg: Constants.ERROR_NOTIFICATION,
          id: -1
        })
      })

    } else {
      NotificationService.createNotification({
        targetCode: notificationItem.targetCode.toUpperCase(),
        targetType: notificationItem.type.toUpperCase()
      }).then(response =>{
        const msg: string = Utils.toTitleCase(notificationItem.targetCode) + ' notification active.';
        dispatch(addToast({
          bg: 'bg-success',
          msg: msg,
          id: -1
        }))
        dispatch(addNotification(response[0]));
      }).catch( e =>{
        addToast({
          bg: 'bg-danger',
          msg: Constants.ERROR_NOTIFICATION,
          id: -1
        })
      });
    }

    const index: number = allNotifications.findIndex(n=>n.targetCode === notificationItem.targetCode);
    const notificationItemClone: INotificationItem = {...notificationItem};
    const allNotificationsClone: INotificationItem[] = [...allNotifications];
    allNotificationsClone.splice(index, 1, notificationItemClone);

    setAllNotifications(allNotificationsClone);
  }

  const toggleSwitch = (id: string, label: string, checked: boolean, action: Function): JSX.Element => {
    return <div className={'switch-container d-flex justify-content-between my-1'}>
      <div className={'label me-2'}><p>{label}</p></div>
      <Switch
        id={id}
        checked={checked}
        toggle={()=>{action(id)}}
      />
    </div>
  }

  return (
    <div className={'settings-general content px-5 pt-4 pb-5 bg-white rounded-md'}>

      <h3 className={'mb-0'}><strong>General Settings</strong></h3>
      <h5 className={'mt-2 mb-5'}>Tailor you setting to match your preferences, optimizing you experience for maximum efficiency</h5>

      {/* Search start default */}
      <h5 className={'my-0'} ><b>Default starting search</b></h5>
      <p className={'my-2'}>Choose between simple search or advanced search to have the same experience each time you log in</p>

      <ButtonGroup aria-label={'Default search'} className={'sm mb-2'}>
        {toggleButton(Constants.SIMPLE_SEARCH, (settings.search === Constants.SIMPLE_SEARCH) )}
        {toggleButton(Constants.ADVANCED_SEARCH, (settings.search === Constants.ADVANCED_SEARCH) )}
      </ButtonGroup>

      {/* Search results default */}
      <h5 className={'mt-4 mb-0'}><b>Default search results</b></h5>

      <div className={'mt-0 mb-4'}>
        <div className={'mt-2 mb-3'}>
          <span>Select whether to always display restricted documents, folders and potential duplicates in your sear results</span>
          {/*<Button className={'ms-2 btn-icon-only text-primary'}>
            <i className={'bi bi-info-circle'}/>
          </Button>*/}
        </div>

        <ul className={'switches'}>
          <li className={'border-top py-2'}>{toggleSwitch(Constants.DUPLICATES, 'Display duplicates', settings.search_results.duplicates, handleToggle)}</li>
          <li className={'border-top py-2'}>{toggleSwitch(Constants.FOLDERS, 'Display folders', settings.search_results.folders, handleToggle)}</li>
          <li className={'border-top py-2'}>{toggleSwitch(Constants.RESTRICTED, 'Display restricted', settings.search_results.restricted, handleToggle)}</li>
        </ul>
      </div>

      <p className={'mb-2'}>Select your preferred sorting criteria for search results</p>
      <SortDropdown context={Constants.SETTINGS}/>

      <p className={'mt-4 mb-2'}>Choose the number of rows displayed</p>
      <ResultsPerPageDropdown context={Constants.SETTINGS}/>

      {/* Notification Settings */}
      <div className={'notifications pt-2'}>
        <h5 className={'font-light mt-4 mb-1'}><i className={'bi bi-bell me-2'}></i><b>Notifications Settings</b></h5>

        {!userState.loggedIn &&
          <p>You must be logged for this feature.</p>}

        {<Form noValidate>
          <ul className={'switches'}>
            {allNotifications.map((notificationItem:INotificationItem, i:number) =>{
              return (
                <li className={'border-top py-2'} key={i}>
                  <div className={'switch-container d-flex justify-content-between my-1'}>
                    <div className={'label me-2'}><p>{notificationItem.label} Industry Documents</p></div>
                    <Switch
                      id={notificationItem.targetCode}
                      checked={notificationItem.active}
                      toggle={()=>{handleNotificationToggle(notificationItem.targetCode)}}
                    />
                  </div>
                </li>
              )})}
            </ul>
        </Form>}
      </div>

    </div>
  )
}

export default SettingsGeneral;
