import './styles.scss';
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";

/* types, constants, utils */
import * as Constants from "../../../../../constants";
import {IViewerRecordContext, ViewerRecordContext} from "../../../../../types/document";

/* bootstrap */
import {Button} from "react-bootstrap";

/* redux */
import {IResultsState} from "../../../../../types/redux/result";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux";
import {clearResults, setResults, setSelectedResults} from "../../../../../redux/slices/result-slice";
import {ISearchState} from "../../../../../types/redux/search";

/* api */
import {useQuery} from "react-query";
import SearchService from "../../../../../api/search";

/* components */
import ViewerCard from "../../../viewer-card";
import Spinner from "../../../../util/spinner";
import Paging from "../../../../toolbars/components/search-paging";
import {setCurrentPage, setResultsPerPage} from "../../../../../redux/slices/search-slice";

interface IProps {
  handleSelect(document: any): void;
  setNumOfRecords(value:number): void;
}

const ViewerSearchList = (props: IProps):JSX.Element => {
  const {
    handleSelect,
    setNumOfRecords
  } = props;

  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const selectedDocument: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const dispatch = useDispatch();

  const overflowRef = useRef<HTMLInputElement | null>(null);
  const [initialFetch, setInitialFetch] = useState<boolean>(true);
  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const hasQueryParam: boolean = searchParams.get(Constants.QUERY_KEY) !== null
  const pageRefreshRef = useRef<boolean>(false);

  const {isError, isSuccess, isLoading, data, refetch} = useQuery<any, Error>(
    ['vw_' + JSON.stringify(searchState)],
    async ({signal}) => {
      const isFacetQuery: boolean = false;
      return await SearchService.solrDocumentQuery(searchState, signal, isFacetQuery)
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(() => {
    /*
      case 1: Arrived from a search results on home page - one new query w/ 10 results
      case 2: Refreshed the browser after case 1 - two new queries, initial to get position in search for search-paging, second w/ 10 results
      case 3: Arrive via shared link
    */

    if ((resultsState.selected[0].index === -1) && (hasQueryParam)) {
      /* case 2: initial fetch when page is refreshed w/ query params available */
      pageRefreshRef.current = true;
      refetch();
    } else if (initialFetch) {
      /* All cases */
      let index: number = resultsState.selected[0].index;
      if (index === -1) {
        /* Case 3: link without search params*/
        index = 0;
      }
      /* Case 1 & 2 */
      dispatch(setResultsPerPage(Constants.VIEWER_RESULTS_PER_PAGE));
      const page: number = Math.floor(index/Constants.VIEWER_RESULTS_PER_PAGE) + 1;
      dispatch(setCurrentPage(page));
    } else {
      /* Arrived from a search results on home page (Case 1) */
      refetch();
    }
    setInitialFetch(false);

  }, [searchState.current_page, resultsState.selected, initialFetch, dispatch, hasQueryParam, refetch])

  /* useEffect: case 2 - page is refreshed and initial search has results */
  useEffect(()=> {
    if (pageRefreshRef.current && (resultsState.results.length > 0)) {
      const selection: string = searchParams.get(Constants.ID) ? searchParams.get(Constants.ID) : resultsState.results[0].id;;
      dispatch(setSelectedResults([selection]));
      dispatch(clearResults());
      pageRefreshRef.current = false;
      refetch();
      setInitialFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  /* useEffect: scroll to selected once initial list has been rendered */
  useEffect(()=>{
   if (resultsState.results.length > 0) {
     let selectedIndex: number = resultsState.results.findIndex((result: any) => result.id === resultsState.selected[0].id);
     const resultNumber: number = (searchState.current_page - 1) * searchState.results_per_page + selectedIndex;
     const cardElemTop: number | undefined = document.getElementById('card-'+resultNumber)?.offsetTop;
     if (overflowRef.current && cardElemTop) {
       overflowRef.current.scrollTop = cardElemTop;
     }
    }
  }, [resultsState.results, dispatch, resultsState.selected, searchParams, searchState.current_page, searchState.results_per_page])

  /* useEffect: render results */
  useEffect(() => {
    if (isSuccess) {
      dispatch(clearResults()); // remove last results
      setNumOfRecords(data.response.numFound);
      const count: number = data.response.docs.length;
      if (count > 0) {
        dispatch(setResults({data: data, db_set: Constants.DOCUMENTS}))
      } else {
        dispatch(clearResults());
        // todo: jain - handle doc with id not found
      }
   } else if (isError) {
     // todo: jain - handle error
   }

  },[isSuccess, data, dispatch, isError, setNumOfRecords, searchState.current_page])

  return (
    <div className={'viewer-search-list overflow-scroll pt-1'} ref={overflowRef}>
      <div className={'cards d-flex flex-column pb-2'}>

        {/* Loading Spinner */}
        {isLoading &&
          <div className={'text-center pt-4'}>
            <Spinner size={Constants.SMALL}/>
          </div>}

        {!isLoading &&
          <Paging previous={'Previous 10 records'}/>}

        {/* Document Cards */}
        {!isLoading && !initialFetch && resultsState.results.map((result: any, index: number) => {
          return (
            <Button
              key={`dc-${index}`}
              className={'viewer-card-wrapper mb-2 text-dark p-0 bg-transparent text-start'}
              active={selectedDocument && (selectedDocument.id === result.id)}
              onClick={()=>handleSelect(result)}
            >
              <ViewerCard
                index={index}
                record={result}
                page={searchState.current_page}
                resultsPerPage={searchState.results_per_page}
              />
            </Button>
          )
        })}

        {!isLoading && <Paging next={'Next 10 records'}/>}
      </div>
    </div>
  )
}
export default ViewerSearchList;
