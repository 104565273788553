import './styles.scss';
import React from "react";

/* types, constants, utils */
import * as Constants from "../../../constants";

/* components*/
import ViewerMetadata from "../viewer-metadata";
import ViewerCite from "../viewer-cite";
import ViewerTagsNotes from "../viewer-save";
import ViewerDownload from "../viewer-download";
import ViewerShare from "../viewer-share";
import {IViewerState} from "../../../types/redux/viewer";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import ViewerRecords from "../viewer-records";
import ReportIssue from "../../report-issue";

interface IProps {
  activeTool: string;
  setActiveTool(value:string):void;
}

const ViewerPanel = (props: IProps):JSX.Element => {
  const {
    activeTool,
    setActiveTool
  } = props;
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  const toolPanel = ():JSX.Element => {
    let tool: JSX.Element;
    switch (activeTool) {
      case Constants.METADATA:
        tool = <ViewerMetadata/>
        break;

      case Constants.SEARCH_RESULTS:
      case Constants.BROWSE:
      case Constants.BATES:
      case Constants.MORE_LIKE_THIS:
      case Constants.POSSIBLE_DUPLICATES:
        tool = <ViewerRecords
          activeTool={activeTool}
          handleToolSelect={setActiveTool}
        />
        break;

      case Constants.CITE:
        tool = <ViewerCite/>
        break;

      case Constants.TAGS_NOTES:
        tool = <ViewerTagsNotes/>
        break;

      case Constants.DOWNLOAD:
        tool = <ViewerDownload/>
        break;

      case Constants.SHARE:
        tool = <ViewerShare/>
        break;

      case Constants.REPORT_ISSUES:
        return tool = <div className={'mt-4'}>
          <ReportIssue defaultSelection={''} />
        </div>;

      default:
        tool = <></>;
    }
    return tool;
  }

  return (
    <div className={'viewer-panel bg-white h-100 border-end ' + (viewerState.panelCollapsed ? ' hidden': '')}>
      {toolPanel()}
    </div>
  )
}
export default ViewerPanel;
