/*
*  Search Menu
*  Applied: views/industries
*  Description: Allows user to toggle between the database sets eg. documents, collections, and bibliography
*/

import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../constants";
import Utils from "../../utils";
import {ISearchStateMenuItem} from "./interface";
import {Category} from "../../api/payload/payload-types";

/* Bootstrap */
import {Nav, Navbar} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {ISearchState} from "../../types/redux/search";
import {RootState} from "../../redux";
import {IContentPage, IContentState} from "../../types/redux/content";
import {AnyAction} from "@reduxjs/toolkit";
import {setContentCategory} from "../../redux/slices/content-slice";

/* Hooks */
import useSearchReset from "../../hooks/useSearchReset";
import {setSearch} from "../../redux/slices/search-slice";
import SearchUtils from "../../utils/search-utils";
import {addCrumb} from "../../redux/slices/breadcrumb-slice";
import ViewSubheader from "../view/view-subheader";
import {useLocation, useNavigate} from "react-router-dom";

const IndustryNavbar = ():JSX.Element => {
  const items: ISearchStateMenuItem[] = Constants.HOME_SEARCH_MENU_ITEMS;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  /* redux */
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const contentState: IContentState = useSelector<RootState, IContentState>((state: RootState):any => state.content);
  const contentItems: IContentPage[] | undefined = contentState.content_pages.filter(p =>  p.industries.title?.replace(' ','-').toLowerCase() === searchState.industry.toLowerCase());

  /* state */
  const [active, setActive] = useState<string>(Constants.HIGHLIGHTS);
  const [expanded, setExpanded] = useState<boolean>(false)

  /* hooks */
  const searchReset: Function = useSearchReset();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=> {
    if (contentState.categories.length > 0) {
      const { section } = Utils.parseIndustrySection();
      const categoryData: Category | undefined = contentState.categories.find(c=>section === c.title?.replace(' ', '-').toLowerCase());

      if (section && categoryData) {
        dispatch(setContentCategory(categoryData));
        setActive(categoryData.id);
        searchReset(null);
      } else {
        setActive(section && (section !== Constants.VIEWER) ? section : Constants.DOCUMENTS);
        dispatch(setSearch(SearchUtils.fromDataSource(window.location.search)));
        dispatch(setContentCategory(null));
      }

      /* !contentState.category => not content eg. documents or bibliography */
      /* (title !== title) => not same category eg. collections second level click don't reset breadcrumb */
      if (!contentState.category  || ((categoryData && contentState) && (categoryData.title.toLowerCase() !== contentState.category.title.toLowerCase()))) {
        const sectionRemap: string = (section === Constants.BIBLIOGRAPHY) ? Constants.PUBLICATIONS : section;
        dispatch(addCrumb({
          label: Utils.getIndustryTitle(searchState.industry).industry_title + ' industry - ' + Utils.toTitleCase(sectionRemap),
          href: '/' + searchState.industry + '/' + section.toLowerCase() + '/',
          level: 1,
          active: true
        }));
      }

      return ()=>dispatch(setContentCategory(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contentState.categories, searchState.db_set, contentState.category, window.location.href])

  const handleDBItemClick = (e:React.MouseEvent, dbSet: string | null):void => {
    e.preventDefault();
    setExpanded(false);

    /* update url and search params */
    const searchParams = new URLSearchParams();
    searchParams.set(Constants.RESULTS_PER_PAGE_KEY, String(searchState.results_per_page ? searchState.results_per_page : Constants.DEFAULT_RESULT_PER_PAGE));
    const url:string = '/' + searchState.industry + '/' + dbSet + '/?' + searchParams.toString();

    /* reset search state */
    searchReset(dbSet);
    dispatch(setContentCategory(null));
    if (location.pathname.split('/').pop() === Constants.VIEWER) {
      navigate(url)
    } else {
      window.history.pushState(null, '', url);
    }
  }

  const handleContentItemClick = (e:React.MouseEvent, data: any | null):void => {
    e.preventDefault();
    setExpanded(false);

    const url:string = '/' + searchState.industry+ '/' + data.categories.title.replace(' ', '-').toLowerCase() + '/';
    if (location.pathname.split('/').pop() === Constants.VIEWER) {
      navigate(url)
    } else if (data && data.categories) {
      window.history.pushState(null, '',url);
    }

    setTimeout(()=>{
      searchReset(null);
      dispatch(setContentCategory(data.categories))
    })
  }

  const NavItem = (label: string, eventKey: string, index:number, data: any):JSX.Element => {
    return (
      <Nav.Item
        role={'presentation'}
        key={"sm-item"+index}
        className={"rounded-1"}>

        <Nav.Link
          className={"mx-4 pt-2 pb-2 px-0 text-black"}
          onClick={e=> data.categories ? handleContentItemClick(e, data) : handleDBItemClick(e, data.db_set)}
          role="tab"
          eventKey={eventKey}
        >
          {Utils.toTitleCase(label)}
        </Nav.Link>
        <div className={(active === eventKey) ? 'active_select' : '' }></div>
      </Nav.Item>
    )
  }

  const heading = ():string =>{
    let returnStr: string  = Utils.getIndustryTitle(searchState.industry).industry_title;
    if (searchState.industry !== Constants.ALL_INDUSTRIES) {
      returnStr += ' ' + Utils.toTitleCase(Constants.INDUSTRY)
    }
    return returnStr;
  }

  return (
    <div className={'industry-navbar'}>
      <Navbar expand={'lg'} expanded={expanded} onToggle={()=>setExpanded(!expanded)} >
        <div className={'subheader-container w-100'}>
          <ViewSubheader
            heading={heading()}
            subHeading={ Utils.getIndustryTitle(searchState.industry).archive_title}
          />

          <Navbar.Toggle
            aria-controls={"industry-navbar"}
            className={'nav-toggle d-inline-block'}
          >
            <div className={'arrow-container'}>
              <div className={(expanded? 'arrow-up': 'arrow-down')}/>
            </div>
          </Navbar.Toggle>
        </div>

        <div className={'nav-container shadow-sm w-100'}>
          <Navbar.Collapse id={'industry-navbar'}>
            <Nav
              className={'justify-content-left bg-white pt-1 mb-0 position-relative rounded-md-bottom'}
              activeKey={active}
              onSelect={(selectedKey) => setActive(selectedKey ? selectedKey : Constants.HIGHLIGHTS)}
            >
              {/* All Industries */}
              {items.map((item:ISearchStateMenuItem, index:number) => {
                const eventKey: string = item.db_set ? item.db_set : Constants.HIGHLIGHTS;
                return NavItem(item.label, eventKey, index, item);
              })}

              {/* Industry specific content pages from CMS */}
              {contentItems.map((item:IContentPage, index:number) => {
                const label: string = item.categories.title ? item.categories.title : '';
                return NavItem(label, item.categories.id , index, item);
              })}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default IndustryNavbar;
