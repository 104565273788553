import './styles.scss';
import React, {Dispatch, useContext} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Components */
import ViewerPDF from "../viewer-pdf";
import ViewerMedia from "../viewer-media";
import ViewerToast from "../viewer-toast";

import DocumentUtils from "../../../utils/document-utils";
import {IFileAttributes, IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import Utils from "../../../utils";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {setContentView} from "../../../redux/slices/viewer-slice";

interface IProps {
  fileAttributes: IFileAttributes;
  viewerRecord: any;
}

const ViewerDocument = (props: IProps):JSX.Element => {
  const {
    fileAttributes,
    viewerRecord
  } = props;

  const viewingRecordContext: IViewerRecordContext = useContext<IViewerRecordContext>(ViewerRecordContext);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const link = (): string => {
    let url: string = '';
    if (viewerRecord.artifact) {
      const artifacts: any  = JSON.parse(viewerRecord.artifact);
      url = Utils.getBucketPath(viewerRecord.id) + '/' + artifacts[0].name;
    }
    return url;
  }

  const relatedDocument =  (): { id: string, type: string } => {
    const artifacts: any  = JSON.parse(viewerRecord.artifact);
    let id: string;
    let type: string;
    for (let i = 0; i < artifacts.length; i++) {
      const mediaType = artifacts[i].mediaType.split('/').slice(-1).pop();
      if ((mediaType === Constants.OCR) || (mediaType=== Constants.PLAIN)) {
        id = artifacts[i].name.substring(0,artifacts[i].name.length - 4);
        type = Constants.OCR;
        break;
      } else if (mediaType === Constants.PDF) {
        id = artifacts[i].name.substring(0,artifacts[i].name.length - 4);
        type = Constants.PDF;
        break;
      }
    }
    return {id, type};
  }

  const viewerCmp = (): JSX.Element => {
    if (!fileAttributes) return <></>

    switch (fileAttributes.type) {
      case Constants.PDF:
        return <ViewerPDF id={viewingRecordContext.viewerRecord.id}/>

      case Constants.WEBSITE:
      case Constants.AUDIO:
      case Constants.VIDEO:
        return <ViewerMedia/>;

      case Constants.ZIP:
        // eg: khcx0257 w/o text
        // eg: jrxf0257 w/ text
        const relatedRecord: {id:string, type:string} = relatedDocument();
        if (relatedRecord.id) {
          dispatch(setContentView(relatedRecord.type));
        }
        return (
          <div>
            <ViewerToast
              variant={'light'}
              icon={'bi-file-earmark-arrow-down'}
              content={<span><a href={link()}>Download</a>  the source file(s) to view this document.</span>}
            />

            {relatedRecord &&
              <div className={'zip-pdf'}>
                <ViewerPDF id={relatedRecord.id}/>
              </div>}
          </div>)

      case Constants.PRIVILEGED:
      case Constants.COPYRIGHT:
      case Constants.CONFIDENTIAL:
        if (DocumentUtils.getPublicVersions(viewerRecord).length > 0) {
          //eg. tmlg0155 - previous confidential and public version available
          return (<ViewerToast
            variant={'success'}
            /*icon={'bi-info-circle'}*/
            content={<span><p>This document was previously designated as confidential.
              Document Tools for both public and restricted versions are available.</p>
              <p>Learn more about <a href={'/help/privileged-and-confidential-documents'}>privileged and confidential documents</a>.</p> {/*todo: jain - need link*/}
          </span>}
          />)
        } else {
          // eg. prwj0233 - confidential
          return (<ViewerToast
            variant={'light'}
            content={<span>Only metadata available.</span>}
          />)
        }
    }

    return <></>
  }

  return (<div className={'viewer-document'}>
    {viewerCmp()}
  </div>)
}
export default ViewerDocument;
