export const INDUSTRIES_QUERY = `
  query Industries {
    Industries(limit: 1000) {
      docs {
        id
        title
      }
    }
  }
`
export const INDUSTRIES_FIELDS = `industries {
  title
  id
}`
