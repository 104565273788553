import './styles.scss';
import React, {useContext} from "react";
import {Button, Container} from "react-bootstrap";


import {ISearchState} from "../../../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import * as Constants from "../../../constants";
import {IPageContext, PageContext} from "../../../types/app";

import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import ToggleNotify from "../../toggle-notify";

interface IProps {
  heading: string;
  subHeading?: string;
}

const ViewSubheader = (props: IProps):JSX.Element => {
  const {
    heading,
    subHeading,
  } = props;

  /* redux */
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  /* context */
  const pageContext: string = useContext<IPageContext>(PageContext).context;

  /* hooks */
  const navigate: NavigateFunction = useNavigate();

  return (
    <Container className={'view-subheading pt-4 pt-lg-3 pb-3 mt-lg-4 w-100'}>
      <div className={'inner'}>
        <div className={'headings me-1 me-lg-3'}>
          <h2 className={'d-inline-block'}>{heading}</h2>
          {subHeading && <h5 className={'text-secondary m-0 mb-1 font-light'}>{subHeading}</h5>}
        </div>

        {(searchState.industry !== Constants.ALL_INDUSTRIES) &&
          (pageContext !== Constants.MY_LIBRARY) &&
            <span>
             {/* <div className={'info-btn mt-2 float-end ps-3 d-inline-block d-md-block'}>
                 <Button
                   className={'btn-icon-only'}
                   onClick={()=>{}}
                 >
                  <i className={'bi bi-info-circle h5'}/>
                </Button>
              </div>*/}
              <div className={'d-inline-block float-end d-md-block'}>
                <ToggleNotify
                  targetCode={searchState.industry}
                  type={'industry'}
                />
              </div>
            </span>
        }

        {(pageContext === Constants.MY_LIBRARY) &&
          <div className={'d-inline-block'}>
            <Button
              className={'settings-btn btn-icon-only link-underline-none d-inline-block text-white'}
              onClick={()=>{navigate('/' + Constants.MY_LIBRARY + '/' + Constants.SETTINGS + '/#' + Constants.MY_ACCOUNT)}}
            >
              <i className={'bi bi-gear me-2 me-1 text-white'}/>
              <span>Settings</span>
            </Button>
          </div>}
      </div>
    </Container>
  )
}
export default ViewSubheader;
