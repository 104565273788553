import './styles.scss';
import React, {Dispatch} from 'react'

import * as Constants from "../../../../../constants";

import {CMSLinkType} from "../Link";
import {Media} from "../Media";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {NavLink, useNavigate} from "react-router-dom";
import {setContentCategory} from "../../../../../redux/slices/content-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import LinkButton from "../../../../../components/button-link";

export const Card: React.FC<
  {
    type: string,
    media?: any,
    title?: string,
    description?: string,
    enablelink?: boolean,
    link?: CMSLinkType,
    greybackground?: boolean
  }
> = props => {
  const {
    type,
    media,
    title,
    description,
    //enablelink,
    link,
    greybackground
  } = props

  const navigate:NavigateFunction = useNavigate();
  const dispatch:Dispatch<AnyAction> = useDispatch();

  if (link) {

    let href: string;
    if ((link.type === 'reference') && (typeof link.reference?.value === 'object') && link.reference.value.slug) {
      href = (link.type === 'reference') && (typeof link.reference?.value === 'object') && link.reference.value.slug
        ? `${link.reference?.relationTo !== 'pages' ? `/${link.reference?.relationTo}/${link.reference?.value.slug}` : ''}/${
          link.reference.value.industries.title.toLowerCase()}/${link.reference.value.categories.title.toLowerCase()}/${link.reference.value.slug}`
        : link.url
    } else {
      href = (link.type === 'reference') && (typeof link.reference?.value === 'object') && link.reference.value.id
        ? `${link.reference?.relationTo !== 'pages' ? `/${link.reference?.relationTo}/${link.reference?.value.id}` : ''}/${
          link.reference.value.industries.title.toLowerCase()}/${link.reference.value.categories.title.toLowerCase()}/${link.reference.value.id}`
        : link.url
    }

    if (!href) {
      href = ''
    }

    href = href.replace('/global', '')

    const nav = ()=>{
      dispatch(setContentCategory(null))
      navigate(href)
    }

    if (type === Constants.MENU) {
      return (
        <NavLink
          className={'content-card card has-link' + (greybackground ? ' grey-background' : '')}
          to={href}
          onClick={()=>{}}
        >
          <div className={'pt-3 px-3 pb-5 mb-3'}>
            {title && <h5 className={'max-lines-4'}><strong>{title}</strong></h5>}
            {description && <p className={'description m-0'}>{description}</p>}
          </div>
        </NavLink>
      )
    } else {
      return (
        <div
          className={'content-card card has-link' + (greybackground ? ' grey-background' : '')}
        >
          {media &&
            <div className={'d-flex justify-content-center'}>
              <div className={'img-container border'}>
                <Media resource={media}/>
              </div>
            </div>
          }

          <div className={'pt-3 px-3 pb-5 mb-3'}>
            {title && <h5 className={'max-lines-4'}><strong>{title}</strong></h5>}
            {description && <p className={'description m-0'}>{description}</p>}
            <div className={'link-container position-absolute'}>
              <LinkButton
                label={link.label}
                clickFunc={nav}
                linkArrow={true}
                underline={true}
                disabled={false}
              />
            </div>
          </div>
        </div>
      )
    }
    //return <div className={'content-card card'}><CMSLink {...link} /></div>
  } else {
    return <div className={'content-card card' + (greybackground ? ' grey-background' : '')}>
      {media &&
        <div className={'d-flex justify-content-center mt-1'}>
          <div className={'img-container border'}>
            <Media resource={media}/>
          </div>
        </div>
      }

      <div className={'py-3 px-3'}>
        {title && <h5 className={'max-lines-4'}>{title}</h5>}
        {description && <p className={'description m-0'}>{description}</p>}
      </div>
    </div>
  }
}
