import './styles.scss';
import React from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* Bootstrap */
import { Col, Container, Row} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IViewerState} from "../../../types/redux/viewer";

/* Components */
import DocumentSearch from "./document-search";
import HidePanel from "./hide-panel";
import PageNavigation from "./page-navigation";
import Zoom from "./zoom";
import ViewerMode from "./viewer-mode";
import FullscreenButton from "./fullscreen-button";
import RotationButton from "./rotation-button";
import AccessibilityButton from "./accessibility-button";

interface IProps {
  activeRecordTool: string;
  fileType: string | undefined;
}

const ViewerToolbar = (props: IProps):JSX.Element => {
  const {
    activeRecordTool,
    fileType
  } = props;
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);

  return (
    <div className={'viewer-toolbar p-0'}>
      {/* Mobile */}
      {(fileType === Constants.PDF) &&
        <Container className={'d-lg-none pt-1 px-0 pb-0 bg-light'}>
          <div className={'g-grey-1 py-1 border-top border-bottom d-flex justify-content-between px-3'}>
            {(fileType === Constants.PDF) &&
              <ViewerMode />
            }
            <PageNavigation />
          </div>

          <DocumentSearch />

          <div className={'px-3 pt-2 bg-white border-top border-bottom mb-3 d-flex justify-content-end me-5'}>
            <RotationButton />
            <Zoom />
          </div>
        </Container>}

      {/* Desktop */}
      <Container className={'pdf-toolbar d-none d-lg-block pt-1 px-0 pb-0 bg-light border-bottom z-0 position-relative rounded-md-top'}>
        <Row className={''}>
          <Col lg={2} >
            <p className={'text-normal ps-3 mt-2 pt-1'}><strong>Document Tools</strong></p>
          </Col>

          <Col className={'tool-title'} lg={2}>
            {!viewerState.panelCollapsed &&
              <h6 className={'mt-2 pt-1'}><b>{Utils.toTitleCase(activeRecordTool)}</b></h6>}
          </Col>

          <Col className={'tools d-inline-flex ps-0 pt-1'}>
            <Row className={'d-flex justify-content-between w-100'}>
              <Col className={'d-flex justify-content-end w-100'}>
                <span className={''}>
                   <DocumentSearch />
                </span>

                <RotationButton />
                <FullscreenButton />

                {((fileType === Constants.PDF) || (fileType === Constants.VIDEO)) &&
                  <span className={'me-2 pe-4'}>
                    <Zoom />
                  </span>}
              </Col>

              <Col className={'d-flex pb-1 justify-content-start w-100'}>
                <span className={'ms-2 me-2'}>
                  <PageNavigation />
                </span>

                {(fileType === Constants.PDF) &&
                  <HidePanel />}

                {(fileType === Constants.PDF) &&
                    <ViewerMode />}

                <AccessibilityButton/>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </div>

  )
}
export default ViewerToolbar;
