export const CATEGORIES_QUERY = `
  query Categories {
    Categories(limit: 1000) {
      docs {
        id,
        title
      }
    }
  }
`




