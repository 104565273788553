import './styles.scss';
import React, {Dispatch} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap */
import {Dropdown} from "react-bootstrap";
import {IViewerState} from "../../../../types/redux/viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {setContentView} from "../../../../redux/slices/viewer-slice";

const ViewerMode = ():JSX.Element => {
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  return(
    <div className={'viewer-mode d-flex'}>
      <span className={'me-1 mt-2'}>View:</span>
      <span className={'me-1 mt-2 text-normal d-lg-none'}><strong>View:</strong></span>

      <Dropdown
        className={'d-inline'}
        onSelect={(eventKey: string | null)=>dispatch(setContentView(eventKey))}
      >
        <Dropdown.Toggle className={'bg-transparent text-black border-0'} variant={'primary'} id={'dropdown-type'}>
          {viewerState.contentView === Constants.TEXT ? Utils.toTitleCase(Constants.TEXT) : viewerState.contentView?.toUpperCase() }
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href={''} eventKey={Constants.PDF}>PDF</Dropdown.Item>
          <Dropdown.Item
            href={''}
            eventKey={Constants.TEXT}
            disabled={!viewerState.ocrAvailable}
          >
            Text
          </Dropdown.Item>
          <Dropdown.Item
            href={''}
            eventKey={Constants.OCR}
            disabled={!viewerState.ocrAvailable}
          >
            OCR
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default ViewerMode;
