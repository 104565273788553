import './styles.scss';
import React, {Dispatch} from "react";

/* Bootstrap */
import {Form} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {addToSelectedResults, clearSelectedResults} from "../../../../redux/slices/result-slice";
import {RootState} from "../../../../redux";
import {IResultsState} from "../../../../types/redux/result";

const SelectResults = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);

  const toggleSelect = ():void => {
    if (resultsState.selected.length > 0) {
      dispatch(clearSelectedResults())
    } else {
      dispatch(addToSelectedResults(resultsState.results.map(result => result.id)))
    }
  }

  return (
    <div className={'select-results m-0 mt-2 ms-lg-3'}>
      <Form className={"d-inline-block"}>
        <Form.Check
          type={"checkbox"}
          id={"select-all-checkbox"}
          onClick={e=>toggleSelect()}
          onChange={()=>{}}
          checked={resultsState.selected.length > 0}
        />
      </Form>
      <span className={'ms-2 ms-lg-3 num-selected'}><i>{resultsState.selected.length} selected</i></span>
    </div>
  )
}
export default SelectResults;
