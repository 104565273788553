import './styles.scss';
import React from "react";

import {ContentBlock} from "../Content";
import {MediaBlock} from "../MediaBlock";
import {CardGrid} from "../CardGrid";
import {CardMenuGrid} from "../CardMenuGrid";

import {ContentGrid} from "../ContentGrid";
import {Collapse} from "../Collapse";
import {IFrameBlock} from "../iFrameBlock";

import AskUsEmail from "../../../../../components/modal/ask-us-modal";
import Utils from "../../../../../utils";
import {SearchableTable} from "../SearchableTable";
import {NewsFeedBlock} from "../NewsFeedBlock";

interface ILayout {
  layout: any
}

const Layout = (props: ILayout):JSX.Element => {
  const {
    layout
  } = props;

  return (<div className={'layout'}>
      {layout.map((_layout: any, i: number) => {

        switch (_layout.blockType) {
          case 'newsFeedBlock':
            return  <NewsFeedBlock
              id={'layout_' + i}
              key={i}
              /* @ts-ignore */
              fields={_layout.newsFeedBlockFields}/>

          case 'SearchableTable':
            return  <SearchableTable
              id={'layout_' + i}
              key={i}
              /* @ts-ignore */
              fields={_layout.searchableTableFields}/>

          case 'content':
            return  <ContentBlock
              id={'layout_' + i}
              key={i}
              columns={_layout.columns}/>

          case 'mediaBlock':
            return <MediaBlock
              id={'layout_' + i}
              key={i}
              media={_layout.media}
              position={_layout.position}
              blockType={'mediaBlock'}/>

          case 'cardMenuGrid':
            if (_layout.cardMenuGridFields.alphabetize) {
              Utils.arrayAlphabetical(_layout.cardMenuGridFields.items, (g:any)=> g.title)
            }
            return  <CardMenuGrid
              id={'layout_' + i}
              key={i}
              /* @ts-ignore */
              fields={_layout.cardMenuGridFields}/>

          case 'cardGrid':
            return  <CardGrid
              id={'layout_' + i}
              key={i}
              fields={_layout.cardGridFields}/>

          case 'emailForm':
            return <AskUsEmail/>

          case 'horizontalLine':
            return <hr className={'my-4'}/>

          case 'iFrameBlock':
            return <IFrameBlock configs={_layout.iFrameBlockFields} />

          case 'contentGrid':
            return <ContentGrid fields={_layout.contentGridFields} />

          case 'collapseBlock':
            return <Collapse fields={_layout.collapseBlockFields} />

          default:
            //console.log('_layout.blockType ' + _layout.blockType)
            return <></>
        }
      })}
    </div>)
}

export default Layout;
