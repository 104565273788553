import './styles.scss';
import React, {useEffect, useState} from "react";

/* Types, Constants, Utils */
import {IResultsState} from "../../../types/redux/result";
import {RecordsContext} from '../../../types/document';
import * as Constants from "../../../constants";

/* Bootstrap */
import {Col, Container, Row} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

/* API */
import Paging from "../components/search-paging";

/* Components */
import ResultsPerPageDropdown from "../components/results-per-page-dropdown";

const PageToolsBottom = ():JSX.Element => {

  const resultState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  /* todo: jain - duplicate use Effect w/ PageToolTop - break out as hook*/
  useEffect(()=>{
    /* Converts results selected list of ids to list of records */
    const records: any[] = resultState.selected.map(selected => {
      return resultState.results.find((record: any) => {
        return record.id === selected.id
      });
    })
    setSelectedRecords(records);
  }, [resultState.selected, resultState.results])

  return (
    <RecordsContext.Provider value={{records:selectedRecords}}>
      <Container className={'page-tools-bottom bg-light py-lg-3 px-0 px-lg-2 rounded-1 border border-1'}>
        <Row className={'w-100 d-flex justify-content-between m-0'}>

          {/* Display per page */}
          <Col lg={"auto"} className={'py-2 py-lg-0'} >
            <ResultsPerPageDropdown context={Constants.PAGE}/>
          </Col>

          {/* Pagination */}
          <Col lg={"auto"} className={'pagination-col py-2 py-lg-0'}>
            <Paging
              previous={'Previous'}
              showPageInput={true}
              next={'Next'}
            />
          </Col>
        </Row>
      </Container>
    </RecordsContext.Provider>
  )
}
export default PageToolsBottom;
