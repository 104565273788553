import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Container} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {IBreadCrumbState, TBreadcrumb} from "../../../types/redux/breadcrumb";
import {useLocation, useNavigate} from "react-router-dom";
import {removeToCrumb} from "../../../redux/slices/breadcrumb-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {setDBSet} from "../../../redux/slices/search-slice";
import usePlatform from "../../../hooks/usePlatform";

const BreadCrumb = ():JSX.Element => {
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);
  const navigate = useNavigate();
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const location = useLocation();
  const isDesktop: boolean = usePlatform().isDesktop;
  const [crumbs, setCrumbs] = useState<TBreadcrumb[]>([]);

  useEffect(()=>{
    const breadcrumbsCln: TBreadcrumb[] = [];
     breadcrumbState.breadcrumbs.forEach( c =>{
       breadcrumbsCln.push({...c})
     })

    //const breadcrumbsCln: TBreadcrumb[] = JSON.parse(JSON.stringify(breadcrumbState.breadcrumbs));
    const generateLabels = ():TBreadcrumb[] =>{
      for (let i = breadcrumbsCln.length-1; i>=0; i--) {
        if (i===0) {
          // ignore home
        } else if (i === breadcrumbsCln.length-1) {
          // last entry just truncate
        } else if (i === breadcrumbsCln.length-2) {
          if (breadcrumbState.breadcrumbs.length > 1) {
            breadcrumbsCln[i].label =  breadcrumbsCln[i].label.substring(0, 10) + '...';
          }
        } else if ((i>0) && (i <= breadcrumbsCln.length-2)) {
          breadcrumbsCln[i].label = '...';
        }
      }
      return breadcrumbsCln;
    }


    if (isDesktop) {
      setCrumbs(breadcrumbsCln)
    } else {
      setCrumbs(generateLabels())
    }
  }, [breadcrumbState.breadcrumbs, isDesktop])

  const goCrumb = (e: any, crumb: TBreadcrumb): void =>{
    e.preventDefault();
    if (!crumb.active) {
      dispatch(setDBSet(null));
      dispatch(removeToCrumb(crumb));
      navigate(crumb.href)
    }
  }

  const hide = ():boolean => {
    return (location.pathname === '/' + Constants.HOME) || ((location.pathname === '/') || (location.pathname === '/' + Constants.INDUSTRIES))
  }

  return (
    <div className={'breadcrumb-custom bg-white' +  (hide() ? ' hidden' : '')}>
     <Container className={'pt-2 '}>

        <Breadcrumb >
          {crumbs.map((bc: TBreadcrumb, i: number) => {
            return (<Breadcrumb.Item
                key={'bc-'+i}
                active={bc.active}
                onClick={e => goCrumb(e, breadcrumbState.breadcrumbs[i])}
                href={''}>
                <div className={'label d-inline-block ellipse me-2'} dangerouslySetInnerHTML={{__html: crumbs[i].label }}/>
              </Breadcrumb.Item>)
          })}
        </Breadcrumb>

      </Container>
      <hr className="w-100 bc-all-industries-100 m-0"/>
    </div>
  )
}
export default BreadCrumb;
