import './styles.scss';
import React, {Dispatch, useEffect} from "react";
import {Button} from "react-bootstrap";

import {setThumbnailsCollapsed} from "../../../../redux/slices/viewer-slice";
import {IViewerState} from "../../../../types/redux/viewer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";

const HidePanel = ():JSX.Element => {
  const viewerState: IViewerState = useSelector<RootState, IViewerState>(state => state.viewer);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  useEffect(()=>{
    return ()=>dispatch(setThumbnailsCollapsed(true))
  }, [dispatch])

  return(
    <div className={'hide-panel me-1'}>
      <Button
        onClick={()=>dispatch(setThumbnailsCollapsed(!viewerState.thumbnailsCollapsed))}
        className={'border-0 btn btn-primary text-dark pt-2' + (viewerState.thumbnailsCollapsed ? ' bg-transparent': '')} // +
      >
        <i className={'bi bi-layout-sidebar ' + (viewerState.thumbnailsCollapsed ? '': ' text-light')}/>
      </Button>
    </div>
  )
}
export default HidePanel;
