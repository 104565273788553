import './styles.scss';
import React, {useState} from "react";

import * as Constants from "../../../../constants";

import {Button} from "react-bootstrap";
import ReportIssueModal from "../../../modal/report-issue-modal";
import LinkButton from "../../../button-link";

const AccessibilityButton = ():JSX.Element => {
  const [reportIssueModalShow, setReportIssueModalShow] = useState<boolean>(false);
  const [showAccessibility, setShowAccessibility] = useState<boolean>(false);
  return(
    <div className={'accessibility'}>
      <Button
        className={'accessibility-btn ms-1 border-0 btn btn-primary pt-2'  + (showAccessibility? ' text-light': ' bg-transparent text-dark')}
        onClick={()=>setShowAccessibility(true)}
      >
        <i className={"bi bi-universal-access-circle"}/>
      </Button>

      {showAccessibility && <div className={'show-accessibility position-absolute bg-white border-grey rounded shadow p-2 pe-4'}>
          <Button className={'close-btn position-absolute btn-icon-only p-0 border-0 bg-secondary'}
            onClick={()=>setShowAccessibility(false)}>
            <i className={'bi bi-x-circle-fill text-light'}></i>
          </Button>

          <p className={'m-0 px-2 mt-4 pt-1'}>This document is accessible.</p>
          <LinkButton
            label={'Report accessibility issues'}
            underline={true}
            clickFunc={()=>{
              setShowAccessibility(false)
              setReportIssueModalShow(true)
            }}/>
      </div>}

      {<ReportIssueModal
        defaultSelection={Constants.ACCESSIBILITY_ISSUES}
        show={reportIssueModalShow}
        onHide={() => setReportIssueModalShow(false)}
      />}
    </div>
  )
}

export default AccessibilityButton;
