import './styles.scss';
import React, {Dispatch, useEffect, useMemo, useState} from "react";


/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {TQueryItem} from "../../../constants";
import SearchUtils from "../../../utils/search-utils";

/* Bootstrap */
import {Container, Row, InputGroup, Form} from "react-bootstrap";

/* redux */
import {setIndustry, setQuery} from "../../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {ISearchState} from "../../../types/redux/search";
import {RootState} from "../../../redux";

/* Hooks */
import useConvertToAdvSearch from "../hooks/useConvertToAdvSearch";


/* Components */
import SearchHistoryDropdown from "./search-history-dropdown";
import SearchSave from "./search-save";
import SearchButton from "./search-button";
import {ISavedState} from "../../../types/redux/saved";
import FiltersSlideOut from "../../filters/filters-slideout";
import ClearSearchField from "./search-clear";
import SearchIndustryDropdown from "./search-industry-dropdown";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";
import useInsertDBFields from "../hooks/useInsertDBFields";

interface IProps {
  advSearch: boolean;
  minimal: boolean;
  fieldSelect: boolean;
}

const SearchInput = (props: IProps):JSX.Element => {
  const {
    advSearch,
    minimal, /* home screen search field minimal ui */
    fieldSelect
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);

  /* state */
  const [input, setInput] = useState<string>('');
  const [fieldsSelect, setFieldsSelect] = useState<string>('');
  const [submittedQuery, setSubmittedQuery] = useState<boolean>(false);
  const [industryDropdown, setIndustryDropdown] = useState<string>(searchState.industry);

  /* hooks */
  const navigate: NavigateFunction = useNavigate();
  const insertDBFields = useInsertDBFields()
  const convertToAdvSearch = useConvertToAdvSearch();
  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  useEffect(()=>{
    if (!SearchUtils.isDefaultQuery(searchState.query) && (savedState.history.length > 0)) {
      setSubmittedQuery(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedState.history])

  const clearField = (): void => {
    setInput('')
    setFieldsSelect('')
  }

  useEffect(()=>{
    /* toggled to advanced search (advSearch) */
    if ((searchState.query.length === 1) && SearchUtils.isDefaultQuery(searchState.query)) {
      clearField()
    } else {
      clearField();
      setInput(SearchUtils.convertSearchQuery(searchState.query));
    }
  }, [advSearch, searchState.query])

  const handleSubmit = (e:React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    e.preventDefault();

    let term: string = input.trim();
    if (term.length === 0) {
      term = Constants.EMPTY_SEARCH;
    }

    if (minimal) {
      dispatch(setIndustry(industryDropdown));
      navigate('/' + industryDropdown + '/documents/?industry=' + industryDropdown + '&q=null%2Call%2Ccontains%2C' + term + '&db-set=documents&sort=relevance&pg=1&npp=20');
      return;
    }

    setTimeout(()=> {
      const queryItem: TQueryItem = {...Constants.DEFAULT_SEARCH};
      queryItem.term = term;
      const query: TQueryItem[] = convertToAdvSearch.convertQuery([queryItem], searchState.db_set);

      /* Query */
      searchParams.set(Constants.QUERY_KEY, SearchUtils.searchQueryToString(query));
      dispatch(setQuery({
        query: query,
        recent_query: true
      }))
    })
  }

  const hasQuery = ():boolean => {
    return input.trim().length !== 0;
  }

  const placeholder = ():string =>{
    switch (searchState.db_set) {
      case Constants.DOCUMENTS:
        return Constants.PLACEHOLDER_DOCUMENTS;

      case Constants.BIBLIOGRAPHY:
        return Constants.PLACEHOLDER_BIBLIOGRAPHY;

      default:
        return Constants.PLACEHOLDER_DOCUMENTS
    }
  }

  const handleFieldAbv = (input:string): void =>{
    const  {
      str,
      fs
    } = insertDBFields.insertField(input);

    if (fieldSelect) {
      setFieldsSelect(fs)
    }
    setInput(str);
  }

  return (
    <div className={'search-input'}>
      {/*
      *
      * MOBILE
      *
      */}
      <Container className={'d-lg-none p-0'}>

        <InputGroup className={'bg-white rounded border'}>
          {advSearch &&
            <h6 className={'adv-search-title text-nav-blue position-relative m-0'}>
              Search Criteria
              <div className={'header-underline mt-2 position-absolute'}/>
            </h6>}

          {minimal &&
            <SearchIndustryDropdown
              industry={industryDropdown}
              setIndustry={setIndustryDropdown}
            />}

          {/* Search input field */}
          {!advSearch &&
            <>
              <div className={'sel-container sel position-absolute'} dangerouslySetInnerHTML={{__html:fieldsSelect}}/>
              <Form.Control
                className={'search-input-field border-0 bg-transparent rounded'}
                placeholder={placeholder()}
                aria-label={placeholder()}
                aria-describedby={'search-input'}
                value={input}

                onChange={e=>{
                  setSubmittedQuery(false);
                  handleFieldAbv(e.target.value);
                }}

                onKeyDown={e=>{
                  if (e.code === Constants.ENTER) {
                    handleSubmit(e)
                  }
                }}
              />
            </>
          }

          {/* Clear input button */}
          {!advSearch &&
            hasQuery() &&
            <ClearSearchField clearField={clearField}/>}

          <div className={'vertical-hr'}/>

          {/* Save Search */}
          {(!minimal) && submittedQuery &&
            <SearchSave/>
          }

          {/* Recent searches dropdown */}
          {(!minimal) &&
            <SearchHistoryDropdown/>
          }

          {/* Search button */}
          <SearchButton
            disabled={!hasQuery()}
            handleSubmit={handleSubmit}/>
        </InputGroup>
      </Container>

      {/*
      *
      * DESKTOP
      *
      */}
      <Container className={'d-none d-lg-block p-0 bg-white rounded'}>
        <Row className={'m-0'}>
          <Container className={'p-0'}>
            <InputGroup className={'border rounded'}>

              {/* Filters button & slideout */}
              {(!minimal) &&
                <FiltersSlideOut label={'Filters'}/>}

              {/* Filters button & slideout */}
              {minimal &&
                <SearchIndustryDropdown
                  industry={industryDropdown}
                  setIndustry={setIndustryDropdown}
                />}

              {advSearch &&
                <h6 className={'adv-search-title text-nav-blue position-relative m-0'}>
                  Search Criteria
                  <div className={'header-underline mt-2 position-absolute'}/>
                </h6>}

              {/* Search input field */}
              {!advSearch &&
                <>
                  <div className={'sel-container sel position-absolute'} dangerouslySetInnerHTML={{__html:fieldsSelect}}/>
                  <Form.Control
                    className={'search-input-field border-0 bg-transparent'}
                    placeholder={placeholder()}
                    aria-label={placeholder()}
                    aria-describedby={'search-input'}
                    value={input}

                    onChange={e=>{
                      setSubmittedQuery(false);
                      handleFieldAbv(e.target.value);
                    }}

                    onKeyDown={e=>{
                      if (e.code === Constants.ENTER) {
                        handleSubmit(e)
                      }
                    }}
                  />
                </>
              }

              {/* Clear input button */}
              {!advSearch &&
                hasQuery() &&
                <ClearSearchField clearField={clearField}/>}

              <div className={'vertical-hr'}/>

              {/* Save Search */}
              {(!minimal) && submittedQuery &&
                <SearchSave/>
              }

              {/* Recent searches dropdown */}
              {(!minimal) &&
                <SearchHistoryDropdown/>
              }

              {/* Search button */}
              <SearchButton
                disabled={!hasQuery()}
                handleSubmit={handleSubmit}/>

            </InputGroup>
          </Container>
        </Row>
      </Container>
    </div>



)}

export default SearchInput;
