import './styles.scss';
import {Col, Row, Toast} from "react-bootstrap";
import React, {useState} from "react";

interface IProps {
  icon?: string;
  content: JSX.Element;
  variant: string;
}

const ViewerToast = (props: IProps):JSX.Element => {
  const {
    icon,
    content,
    variant
  } = props;

  const [visible, setVisible] = useState<boolean>(true);

  return (<>
    {visible &&
      <Toast
        className={'viewer-toast mx-auto mt-4'}
        bg={variant}
        onClose={() => setVisible(false)}
      >
        <Toast.Header className={'bg-light border-0'}/>
        <Toast.Body className={'px-3 pb-4 pt-3'}>
          <Row>
            {icon && <Col lg={1}><i className={'text-larger bi ' + icon}></i></Col>}
            <Col>{content}</Col>
          </Row>
        </Toast.Body>
      </Toast>
    }
  </>)
}

export default ViewerToast;
