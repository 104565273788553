import './styles.scss';
import React, {useContext,  useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {CardContext, IContext, IRecordsContext, RecordsContext} from "../../../../types/document";

/* Bootstrap */
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";

/* Components */
import ReportIssueModal from "../../../modal/report-issue-modal";
import ShareModal from "../../../modal/share-modal";
import TagsNotesModal from "../../../tags-notes/tags-note-modal";
import CiteModal from "../../../modal/cite-modal";

interface IProps {
  context: string
}

const PageToolsKebab = (props: IProps) :JSX.Element => {
  const {
    context
  } = props;

  const cardContext: any = useContext<IContext>(CardContext).context;
  const records: any = useContext<IRecordsContext>(RecordsContext).records;

  const [reportIssueModalShow, setReportIssueModalShow] = useState<boolean>(false);
  const [shareModalShow, setShareModalShow] = useState<boolean>(false);
  const [tagsNotesModalShow, setTagsNotesModalShow] = useState<boolean>(false);
  const [citeModalShow, setCiteModalShow] = useState<boolean>(false);

  return (
    <div className={'page-tools-kebab ms-2'}>
      <Dropdown className={'dropdown'}>
        <OverlayTrigger overlay={
          <Tooltip className={records.length ? 'hidden' : ''}>{Constants.TOOLTIP_PAGE_PAGE_TOOLS}</Tooltip>
        }>
          <span> {/* enable bs tooltip to display when button is disabled */}
            <Dropdown.Toggle
              disabled={!records.length}
              className={"btn btn-sm btn-light mt-1"}
            >
              <i className={'bi bi-three-dots'}/>
            </Dropdown.Toggle>
          </span>
        </OverlayTrigger>

        <Dropdown.Menu className={'text-small'}>
          {(cardContext === Constants.DOCUMENT) &&
            <>
              {/* Toolbar ONLY - Add Tag / Note */}
              {(context === Constants.TOOLBAR) &&
                <Dropdown.Item
                  className={''}
                  onClick={() => setTagsNotesModalShow(true)}
                >
                  <i className={'bi bi-tag h5'}/>
                  <span className={'ms-1 me-1 align-middle'}>Add Tag/Note</span>
                </Dropdown.Item>}

              {/* Cite */}
              <Dropdown.Item
                className={'py-2'}
                onClick={() => setCiteModalShow(true)}
              >
                <i className={'bi bi-quote'}/>
                <span className={'ms-2 me-1'}>Cite</span>
              </Dropdown.Item>

              {/* Share */}
              <Dropdown.Item
                className={'py-2'}
                onClick={() => setShareModalShow(true)}
              >
                <i className={'bi bi-share'}/>
                <span className={'ms-2 me-1'}>Share</span>
              </Dropdown.Item>

              {/* Divider */}
              <Dropdown.Divider />
            </>}

          {/* Report an Issue */}
          <Dropdown.Item
            onClick={()=>setReportIssueModalShow(true)}
          >
            <span>Report Issue</span>
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

      {<ReportIssueModal
        show={reportIssueModalShow}
        onHide={() => setReportIssueModalShow(false)}
      />}

      {<ShareModal
        show={shareModalShow}
        onHide={() => setShareModalShow(false)}
      />}

      {tagsNotesModalShow &&
        <TagsNotesModal
          onHide={() => setTagsNotesModalShow(false)}
      />}

      {<CiteModal
        show={citeModalShow}
        onHide={() => setCiteModalShow(false)}
      />}

    </div>
  )
}
export default PageToolsKebab;
