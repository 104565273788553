import * as Constants from "../../../constants";
//import METADATA from "../../../assets/data/search.meta.json";
import {TQueryItem} from "../../../constants";

const useConvertToAdvSearch = (): { convertQuery: Function } => {

 /* const mapDateField = (field:string, dbSet: string): string | null =>{
    let fieldDataReverse: any;
    let allFields: any;
    switch (dbSet) {
      case Constants.DOCUMENTS:
        fieldDataReverse = METADATA.allFields_reverse;
        allFields = METADATA.allFields;
        break;

      case Constants.BIBLIOGRAPHY:
        fieldDataReverse = METADATA.biblioAllFields_reverse;
        allFields = METADATA.biblioAllFields;
        break;
    }

    const reverseLookup: any = fieldDataReverse;
    let resolvedField: string = null;
    const fieldLookup: string | undefined = reverseLookup[field];
    if (fieldLookup) {
      const terms: string = allFields[fieldLookup[0]];
      if (terms){
        const term: string = terms[0];
        if (term) {
          let value: string[];
          for (let key in  allFields) {
            value = allFields[key];
            if ((value[0] === term) && (value.length === 1)){
              resolvedField = key
            }
          }
        }
      }
    }

    return resolvedField
  }*/

  const remapNegOne = (index: number, term: string): number => {
    return (index === -1) ? term.length + 1 : index
  }

  const convertQuery = (searchQuery: TQueryItem[], dbSet: string): TQueryItem[] => {
    searchQuery = JSON.parse(JSON.stringify(searchQuery));

    if (searchQuery.length === 1 ) {
      let terms:TQueryItem[] = [];
      const term: string = searchQuery[0].term;
      if ((term.indexOf(' AND ') !== -1) || (term.indexOf(' OR ') !== -1)) {
        let done: boolean = false;
        let newQueryItem: TQueryItem;
        let andIndex: number = -1;
        let orIndex: number = -1;
        let notIndex: number = -1;
        let termScratch: string = term;
        let operator: string | null = null;

        while (!done) {
          newQueryItem = {...searchQuery[0]}
          newQueryItem.operator = operator;
          andIndex = termScratch.indexOf(` ${Constants.AND} `);
          orIndex = termScratch.indexOf(` ${Constants.OR} `);
          notIndex = termScratch.indexOf(` ${Constants.NOT} `);

          if ((andIndex === -1) && (orIndex === -1) && (notIndex === -1)) {
            newQueryItem.term = termScratch;
            done = true;
          } else {
            const min:number = Math.min(
              remapNegOne(andIndex, newQueryItem.term),
              remapNegOne(orIndex, newQueryItem.term),
              remapNegOne(notIndex, newQueryItem.term));
            newQueryItem.term = termScratch.substring(0, min);

            switch (min) {
              case orIndex:
                operator = Constants.OR;
                break;
              case notIndex:
                operator = Constants.NOT;
                break;
              default:
                operator = Constants.AND;
            }
            termScratch = termScratch.substring(min + (` ${operator} `).length, termScratch.length);
          }
          terms.push(newQueryItem);
        }
        searchQuery = terms;
      }
    }

    return searchQuery;
  }

  return {
    convertQuery
  }
};

export default useConvertToAdvSearch;
