import './styles.scss';
import React from 'react'

import * as Constants from "../../../../../constants";
import {Card} from "../Card";
import {Col, Container, Row} from "react-bootstrap";

export const CardGrid: React.FC<
  {
    fields: any,
    id?: string
  }
> = props => {
  const {
    fields
  } = props

  const sanitizeAttributes = (attrs: any): any => {
    const sanitizedAttr: any = {};
    for (let key in attrs) {
      sanitizedAttr[key.toLowerCase()] = attrs[key]
    }
    return sanitizedAttr
  }

  const columns =():any[]=>{
    const struct: any[] = [];
    let colNum: number = 0;
    let rowNum: number = 0;
    let row:any[];
    for (let i=0; i<fields.cards.length; i++) {
      if (struct.length === rowNum){
        struct.push([])
      }
      row = struct[rowNum];

      if (row.length === colNum) {
        row.push([])
      }
      row[colNum].push(fields.cards[i]);

      colNum = (colNum + 1 ) % 3;
      if (colNum === 0) {
        rowNum++;
      }
    }
    return struct;
  }

  return (
   <Container className={'card-grid'}>
      {columns().map((r:any, ri: number) =>{
        return <Row key={'row_'+ri} className={'cards justify-content-left'}>
          {r.map((c:any, i:number)=>{
            return <Col key={'col_' + i} lg={4} className={'grid-col'}>
              <Card key={'card' + i} type={Constants.CARD}{...sanitizeAttributes(c[0])}/>
            </Col>
          })}
        </Row>
      })}
    </Container>
  )
}
