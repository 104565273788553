import SORTS from "../assets/data/sorts.json";
import {TSort, TSorts} from "../types/search";
import {useCallback, useEffect, useState} from "react";
import * as Constants from "./../constants";
import {ISearchState} from "../types/redux/search";
import {useSelector} from "react-redux";
import {RootState} from "../redux";

export interface IUseSorts {
  getSort (id: string | null): TSort;
  getAvailableSorts (): TSort[];
}

const useSorts = (pageContext: string): IUseSorts => {
  let dbSet: string = useSelector<RootState, ISearchState>((state: RootState):any => state.search).db_set;

  if (pageContext === Constants.SETTINGS) {
    pageContext = Constants.INDUSTRY;
    dbSet = Constants.DOCUMENTS;
  }

  const sortsDB: TSorts = SORTS as TSorts;
  /* @ts-ignore */

  const getSorts = useCallback((): TSort[] =>{
    let sorts: any = sortsDB[pageContext];
    if (pageContext === Constants.INDUSTRY) {
      sorts = sorts[dbSet]
    }
    return sorts;
  }, [dbSet, pageContext, sortsDB]);

  const [sorts, setSorts] = useState<TSort[]>(getSorts());

  useEffect(()=>{
    setSorts(getSorts())
  }, [pageContext, sortsDB, dbSet, getSorts])

  const getAvailableSorts = (): TSort[] =>{
    return sorts
  }

  const getSort = (id: string | null): TSort => {
    let returnValue: TSort | undefined = sorts.find(sort=>sort.id === id);
    if (!returnValue) {
      returnValue = sorts[0];
    }
    return returnValue
  }

  return {
    getSort,
    getAvailableSorts
  }
};

export default useSorts;
