import './styles.scss';
import React, {useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import {INDUSTRY_ITEMS} from "../../../../constants";
import {IIndustryMenuItem} from "../../../industry-menu/interface";

/* Bootstrap*/
import {Dropdown} from "react-bootstrap";

interface IProps {
  industry: string | null;
  setIndustry(value: string): void;
}

const SearchIndustryDropdown = (props: IProps):JSX.Element => {
 const {
   industry,
   setIndustry
 } = props;

 const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={'search-industry-dropdown d-relative rounded-start bg-grey-1'}>
      <Dropdown
        onSelect={(selectedKey) => {
          setIndustry(selectedKey ? selectedKey : Constants.ALL_INDUSTRIES)
          setExpanded(false);
        }}
        onToggle={()=> setExpanded(!expanded)}
      >
        <Dropdown.Toggle
          className={'dropdown-button-light rounded-start align-items-center justify-content-center d-flex w-100 text-start bg-transparent p-0 m-0 border-0 h-100'}
          variant={'light'}

        >
          {/* @ts-ignore */}
          <h5 className={'text-grey-darkest m-0 me-3 d-none d-md-block'}>{INDUSTRY_ITEMS.find(i=>i.industry === industry)?.label}</h5>
          <div className={'arrow-container'}>
            <div className={(expanded? 'arrow-up bold': 'arrow-down bold')}/>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu
          aria-expanded="false"
          className={'border-0 rounded-0 rounded-bottom shadow dropdown-menu-light'}
        >
          {INDUSTRY_ITEMS.map((item:IIndustryMenuItem, index:number) => {
            return (
              <Dropdown.Item
                key={"idi-item"+index}
                eventKey={item.industry}
                className={'py-1 px-3 font-xnormal' + ((industry === item.industry) ? ' active' : '')}
              >
                <img className={'icon me-3'} alt={''} src={'/assets/images/' + item.icon} />
                {item.label}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default SearchIndustryDropdown;
