import './styles.scss';
import React, {Dispatch, useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {FilterOptionContext, TFilter, TFilterOption, TFilterPivot} from "../../../types/search";
import SearchUtils from "../../../utils/search-utils";

/* Bootstrap */
import {Accordion, Button,  Offcanvas} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {ISearchDate, ISearchState} from "../../../types/redux/search";
import {RootState} from "../../../redux";
import {IResultsState} from "../../../types/redux/result";
import {addFilters, setFilters, setSearchDates} from "../../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";

/* Components */
import FiltersFilter from "../filters-filter";
import {useQuery} from "react-query";
import SearchService from "../../../api/search";
import {setResultsFacetFields} from "../../../redux/slices/result-slice";
import Spinner from "../../util/spinner";
import {IPageContext, PageContext} from "../../../types/app";
import {ITagState, TSavedDocumentTag} from "../../../types/redux/saved";
import useFilterOptions, {IUseFilterOptions} from "../hooks/useFilterOptions";

interface IProps {
  label: string;
}

export interface ISelectedMutations {
  add: TFilterOption[],
  remove: TFilterOption[]
}

const FiltersSlideout = (props: IProps):JSX.Element => {
  const {
    label
  } = props;

  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const tags:  TSavedDocumentTag[] = useSelector<RootState, ITagState>(state => state.tags).tags;
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const pageContext: string = useContext<IPageContext>(PageContext).context;

  /* state */
  const [availableFilters, setAvailableFilters] = useState<TFilter[]>([]);
  const [activeKeys, setActiveKeys] = useState<any>([Constants.DISPLAY, Constants.INDUSTRY]);
  const [show, setShow] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<TFilterOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const filterOptions: IUseFilterOptions = useFilterOptions();

  const {data, isSuccess, refetch} = useQuery<any, Error>(
    [JSON.stringify(searchState) + "_facets"],
    async ({signal}) => {
      const isFacetQuery: boolean = true;
      switch (searchState.filters_key) {
        case Constants.DOCUMENTS:
          return await SearchService.solrDocumentQuery(searchState, undefined, isFacetQuery)

        case Constants.BIBLIOGRAPHY:
          return await SearchService.solrBibliographyQuery(searchState, signal, isFacetQuery)
      }
    },
    {
      staleTime: Infinity,
      enabled: false
    }
  );

  useEffect(()=> {
    if (isSuccess) {
      if (data && data.facet_counts) {
        dispatch(setResultsFacetFields({data:data.facet_counts}));
      }
      setLoading(false)
    }
  }, [isSuccess, data, dispatch])

  useEffect(()=> {
    if ((pageContext === Constants.MY_LIBRARY)) {
      return;
    }

    setLoading(true)
    setAvailableFilters([]);
    setSelectedFilters(SearchUtils.defaultFilters(searchState.db_set).default);
    refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.query, searchState.db_set, searchState.industry]);

  useEffect(()=>{
    if ((!resultsState.facet_fields && (searchState.filters_key !== Constants.TAGS))) return;

    const filters: TFilter[] = [];
    // @ts-ignore
    searchState.filters_db[searchState.filters_key].forEach((filterEntry: TFilter) => {
      const filter: TFilter = {...filterEntry}

      if (filter.facet_pivot) {
        filter.pivot_options = filterOptions.facetPivotOptions(filter, resultsState.facet_pivot[filter.facet_pivot]);
      } else if (filter.facet_ranges ) {
        filter.pivot_options = filterOptions.facetFieldsOptions(filter, resultsState.facet_ranges[filter.facet_ranges].counts) as TFilterPivot[];
      } else if (filter.facet_field) {
        filter.options = filterOptions.facetFieldsOptions(filter, resultsState.facet_fields[filter.facet_field]) as TFilterOption[];
      } else if (searchState.filters_key === Constants.TAGS) {
        filter.options = filterOptions.filterTagOptions(filter, tags);
      } else {
        filter.options = filterOptions.filterDBOptions(filter);
        filter.options.forEach(option=>{
          /* update selected base on db value */
          if ((option.selected) && (selectedFilters.findIndex(f => f.id === option.id) === -1)) {
            const selectedFiltersCln: TFilterOption[] = [...selectedFilters];
            selectedFiltersCln.push(option)
            setSelectedFilters(selectedFiltersCln)
          }
        })
      }

      /* add filter to available filters */
      if ((filter.options && (filter.options.length > 0)) || (filter.pivot_options && (filter.pivot_options.length > 0))) {
        if (searchState.industry === Constants.ALL_INDUSTRIES) {
          if (filter.id !== Constants.COLLECTION) {
            filters.push(filter)
          }
        } else {
          if (filter.id !== Constants.INDUSTRY) {
            filters.push(filter)
          }
        }
      }
    });

    setAvailableFilters(filters);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.applied_filters, resultsState.facet_fields, resultsState.facet_pivot, resultsState.facet_ranges, tags])

  const handleExpand = (filterEntry: TFilter, index: number, value: boolean): void =>{
    const updatedFilters: TFilter[] = [...availableFilters];
    const filter: TFilter = {...filterEntry}
    filter.expanded = value;
    updatedFilters[index] = filter;
    setAvailableFilters(updatedFilters);
  }

  const handleToggleItem = (key: string):void => {
    const index = activeKeys.indexOf(key);
    if (index > -1) {
      activeKeys.splice(index, 1);
      setActiveKeys([...activeKeys]);
    } else {
      setActiveKeys(activeKeys.concat(key));
    }
  }

  const handleReset = (): void => {
    const resetFilters: TFilter[] = [...availableFilters];
    resetFilters.forEach((filter:TFilter)=>{
      if (filter.options) {
        filter.options.forEach((option:TFilterOption) => {
          option.selected = option.invert;
        })
      }

      if (filter.pivot_options) {
        filter.pivot_options.forEach((filterPivot:TFilterPivot) => {
          filterPivot.selected = false;

          filterPivot.options.forEach((option:TFilterOption) => {
            option.selected = false;
          });
        })
      }
    })

    /* todo: jain - sort out why React not re-rendering and remove flicker */
    setAvailableFilters([])
    setTimeout(()=> {
      setSelectedFilters(SearchUtils.defaultFilters(searchState.db_set).default);
      setAvailableFilters(resetFilters)
    })
  }

  const addRemoveSelectedOptions = (mutations: ISelectedMutations): void => {
    const selectedFiltersCln: TFilterOption[] = [...selectedFilters];
    mutations.add.forEach((filterOption: TFilterOption) =>{
      const index: number = selectedFilters.findIndex(f=>f.id === filterOption.id);
      if (index === -1) {
        if (filterOption.add === Constants.PREPEND) {
          selectedFiltersCln.unshift({...filterOption});
        } else {
          selectedFiltersCln.push({...filterOption});
        }
      }
    })

    mutations.remove.forEach((filterOption: TFilterOption) =>{
      const index: number = selectedFiltersCln.findIndex(f=>f.id === filterOption.id);
      if (index !== -1) {
        selectedFiltersCln.splice(index, 1);
      }
    })
    setSelectedFilters(selectedFiltersCln);
  }

  const handleApply = (): void => {
    setShow(false);
    let resetFilterOptions: TFilterOption[] = SearchUtils.resetFilters(searchState.applied_filters, Constants.FILTERS);
    dispatch(setFilters(resetFilterOptions));

    const selectedFiltersCln: TFilterOption[] = [...selectedFilters]
    const dateOptionsSplit: any = selectedFiltersCln.reduce((acc: any, cur: TFilterOption) => {
      if (cur.fq_group === Constants.DATE) {
        acc.dates = [...acc.dates || [], cur];
      } else {
        acc.other = [...acc.other || [], cur];
      }
      return acc;
    }, {});

    if (dateOptionsSplit.dates) {
      const searchDates: ISearchDate[] = dateOptionsSplit.dates.map((fo:TFilterOption) => {
        const startDate: Date = new Date(fo.value);
        const yearsRange: number = (fo.id.indexOf(Constants.SELECT_ALL) !== -1) ? 10 : 1;
        const endDate: Date = new Date(startDate.getFullYear() + yearsRange, startDate.getMonth(), startDate.getDate() )
        return {
          field: fo.field,
          from: startDate.toLocaleDateString(),
          to: endDate.toLocaleDateString()
        }
      })
      dispatch(setSearchDates(searchDates))
    } else {
      dispatch(setSearchDates([]));
    }

    if (dateOptionsSplit.other) {
      dispatch(addFilters(dateOptionsSplit.other));
    }
  }

  return (
    <div className={'filters-slideout'}>
      <FilterOptionContext.Provider value={
        { addRemoveSelectedOptions: addRemoveSelectedOptions,
          selectedFilters: selectedFilters}
      }>
        <Button
          id={'button-filters'}
          className={'px-3'}
          variant={'light'}
          onClick={() => setShow(true)}
        >
          <i className={'bi bi-filter-left text-larger me-1'}></i>
          <span className={'text-small align-middle'}>{label}</span>
        </Button>

        <Offcanvas
          className={'slideout'}
          show={show}
          onHide={() => setShow(false)}>
          <Offcanvas.Header closeButton className={'pb-0 ps-4'}>
            <Offcanvas.Title>Filter</Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body className={'px-0'}>
            <div className={'d-flex justify-content-between border-bottom pb-3 px-4'}>

              {/* Reset */}
              <Button className={'btn-link link-underline-none p-0 text-xnormal'} onClick={handleReset}>
                Reset
              </Button>

              {/* Apply */}
              <Button
                onClick = {handleApply}
                className={'text-small px-4'}
              >
                Apply
              </Button>
            </div>

            <div className={'filters position-absolute overflow-auto w-100'}>
              <Accordion className={'filters-checkboxes'} flush alwaysOpen activeKey={activeKeys}>
                {loading &&
                  <div className={'mt-4 text-center'}>
                    <Spinner size={Constants.MEDIUM}/>
                    <div className={'text-small p-4'}>
                      <p className={'p-0 m-0'} >Filters load after query results are available.</p>
                      <p className={'p-0 m-0 mt-2'} >This may take a moment... </p>
                    </div>
                  </div>}

                {/* for all filters */}
                {!loading && availableFilters.map((filter: TFilter, filterIndex: number) => {
                  return <FiltersFilter
                    key={filter.id + filterIndex}
                    opened={activeKeys.indexOf(filter.id) !== -1}
                    filter={{...filter}}
                    filterIndex={filterIndex}
                    handleToggleItem={handleToggleItem}
                    handleExpand={handleExpand}/>
                  })
                }
              </Accordion>
            </div>

          </Offcanvas.Body>
        </Offcanvas>
      </FilterOptionContext.Provider>
    </div>
  )
}
export default FiltersSlideout;
