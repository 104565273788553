import './styles.scss';
import React, {useState} from "react";

/* Bootstrap */
import {Button} from "react-bootstrap";

import ShareModal from "../../../../components/modal/share-modal";

interface IProps {
  id: string
}

const CollectionToolbar = (_props: IProps):JSX.Element => {

  const [shareModalShow, setShareModalShow] = useState<boolean>(false);

  const handleShareClick = ()=> {
    setShareModalShow(true)
  }
  
  return (
    <div className={'collection-toolbar mt-3 mt-lg-4 position-absolute'}>
      {/* Share */}
      <Button
        className={'btn-icon-only'}
        onClick={()=>handleShareClick()}
      >
        <i className={'bi bi-share text-black h5 me-0 me-lg-3'}/>
      </Button>

      {/* Share modal */}
      {<ShareModal
        show={shareModalShow}
        onHide={() => setShareModalShow(false)}
      />}
    </div>
  )
}

export default CollectionToolbar;
