import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from '../../../../utils';
import {ISearchState} from "../../../../types/redux/search";

/* Bootstrap*/
import {Col, Container, Dropdown, OverlayTrigger, Row, Tooltip} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {ISearchEntry} from "../../../../types/redux/search";
import {RootState} from "../../../../redux";
import {setSearch} from "../../../../redux/slices/search-slice";

import {ISavedState} from "../../../../types/redux/saved";
import SearchUtils from "../../../../utils/search-utils";
import {TQueryItem} from "../../../../constants";
import LinkButton from "../../../button-link";
import usePlatform from "../../../../hooks/usePlatform";

const SearchHistoryDropdown = ():JSX.Element => {
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const savedState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);
  const search: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const [searchEntryByIndustries, setSearchEntryByIndustries] = useState<any>({});
  const isMobile = usePlatform().isMobile;

  const handleRecentItemClick = (url: string):void => {
    dispatch(setSearch(SearchUtils.fromDataSource(decodeURIComponent(url.split('?')[1]))));
  }

  const getLabel = (url: string): JSX.Element => {
    const searchParam:URLSearchParams  = new URLSearchParams(url);
    const searchParamValue: string | null = searchParam.get(Constants.QUERY_KEY);
    if (searchParamValue) {
      const queryItems: TQueryItem[] = SearchUtils.convertQueryParams(searchParamValue);
      const query: string = SearchUtils.convertSearchQuery(queryItems);
      return <div className={"small"}>{query}</div>
    }

    return <></>
  }

  useEffect(()=>{
    let entry: ISearchEntry;
    let sorted: any = {};

    for (let i=0; i<savedState.history.length; i++) {
      entry = savedState.history[i];
      const searchParam:URLSearchParams  = new URLSearchParams(entry.url.split('?')[1]);
      let industryParamValue: string | null = searchParam.get(Constants.INDUSTRY_KEY);
      if (!industryParamValue) {
        /* fail case should never happen */
        industryParamValue = Constants.ALL_INDUSTRIES
      }

      if (sorted[industryParamValue]) {
        sorted[industryParamValue].push({...entry})
      } else {
        sorted[industryParamValue] = [entry]
      }
    }

    setSearchEntryByIndustries(sorted);
  }, [savedState.history])

  return (
    <div className={'search-history'}>
      <Dropdown>
        <OverlayTrigger overlay={
          <Tooltip className={'mb-3'}>{(search.db_set === Constants.DOCUMENTS) ? 'Search history' : Constants.TOOLTIP_RECENT_SEARCH}</Tooltip>
        }>
          <span> {/* enable bs tooltip to display when button is disabled */}
            <Dropdown.Toggle
              disabled={(search.db_set !== Constants.DOCUMENTS)}
              id={'dropdown-basic'}
              className={"btn-transparent border-0 rounded-0 h-100 px-0"}
            >
              <i className={"bi-clock fst-normal text-primary ms-2"}/>
            </Dropdown.Toggle>
          </span>
        </OverlayTrigger>

        <Dropdown.Menu aria-expanded="false" className={'border-0 border-top rounded-0 rounded-bottom shadow'}>
          {/* heading */}
          <Container className={'p-0 '}>
            <Row className={'d-flex'}>
              <Col className={'ms-2 ms-lg-4 mt-2 d-flex'}>
                <i className={"ps-1 bi-clock fst-normal text-black"}/>
                <h6 className={'mb-0 ms-2 d-inline-block pt-1'}><strong>Search History</strong></h6>
              </Col>
              <Col className={'my-0 p-0 text-end me-2 me-lg-3'} >
                <LinkButton
                  label={isMobile ? 'Manage' : 'Manage Search History'}
                  underline={true}
                  link={'/' + Constants.MY_LIBRARY + '?' + Constants.MY_LIBRARY_SECTION_KEY + '=' + Constants.SEARCH_HISTORY}
                />
              </Col>
            </Row>
          </Container>

          <hr className={'mt-1 mb-2'}></hr>

          {/* dropdown items */}
          <Container className={'results px-1 py-0'}>
            <Row className={'d-flex p-0 m-0'}>
              <Col>
                <span className={'ms-2'}><b>Query</b></span>
              </Col>
              <Col className={'text-end me-2'}>
                <span><b>Results</b></span>
              </Col>
            </Row>

            <Row className={'d-flex p-0 m-0'}>
              <Col>
                {(savedState.history.length === 0) &&
                  <Dropdown.Item
                    key={'rs-nf'}
                    href="#"
                    className={"m-0 ps-3 pt-2"}
                  >
                    {Constants.NO_RECENT}
                  </Dropdown.Item>
                }
              </Col>
            </Row>

            {(savedState.history.length  > 0) &&
              <Row className={'d-flex p-0 m-0'}>
                {Object.keys(searchEntryByIndustries).map((industry: string, iIndex: number) => {
                  return (
                    <div className={'industry-segment px-2'} key={iIndex}>
                      <h6 className={'ms-lg-3 mt-1 mb-0 pt-1 text-xnormal'}>{Utils.toTitleCase(industry)}:</h6>
                      {searchEntryByIndustries[industry].map((searchEntry: ISearchEntry, qIndex: number) =>{
                        return (
                          <Row>
                            <Dropdown.Item
                              key={iIndex+'-'+qIndex}
                              className={'text-primary'}
                              onClick={()=>handleRecentItemClick(searchEntry.url)}
                            >
                              <div className={'ps-2 ps-lg-4 pe-2 pe-lg-3 d-flex justify-content-lg-between'}>
                                <Col className={'ms-2'}>{getLabel(searchEntry.url)}</Col>
                                <Col className={'text-end'}>{Utils.formatNumber(searchEntry.count)}</Col>
                              </div>
                            </Dropdown.Item>
                          </Row>
                        )
                      })}

                      <hr className={"dropdown-divider"}/>
                    </div>
                  )})}
              </Row>}
          </Container>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
export default SearchHistoryDropdown;
