import './styles.scss';
import React, {Dispatch, useCallback, useEffect, useState} from "react";
import {Form, OverlayTrigger, Tooltip} from "react-bootstrap";

import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {addNotification, removeNotifications} from "../../redux/slices/user-slice";
import {INotification, IUserState} from "../../types/redux/user";
import {RootState} from "../../redux";
import LoginPromptModal from "../modal/login-prompt-modal";
import NotificationService from "../../api/notifications";
import {addToast} from "../../redux/slices/toast-slice";
import Utils from "../../utils";
import {ISearchState} from "../../types/redux/search";
import * as Constants from "../../constants";

interface IProps {
  targetCode: any;
  type: string;
}

const ToggleNotify = (props: IProps):JSX.Element => {
  const {
    targetCode,
    type
  } = props;

  const dispatch:Dispatch<AnyAction> = useDispatch();
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);
  const [notifyOn, setNotifyOn] = useState<boolean>(false)
  const [pendingNotify, setPendingNotify] = useState<boolean>(false)
  const notifications: INotification[] = useSelector<RootState, IUserState>(state => state.user).notifications;

  useEffect(()=>{
    if (userState.notifications && searchState.industry) {
      const index: number = userState.notifications.findIndex(n => {
        return n.targetCode.toLowerCase() === searchState.industry
      });
      setNotifyOn(index !== -1)
    }
  },[userState.notifications,  searchState.industry]);

  const removeNotify = useCallback(async ():Promise<any> =>{
    const notification: INotification = notifications.find(n => n.targetCode.toLowerCase() === targetCode.toLowerCase());
    const params: any =  {
      notificationRequestIds: notification.id
    }
    await NotificationService.deleteNotifications(params).then(response =>{
      dispatch(removeNotifications([notification.id]));
      const msg: string = Utils.toTitleCase(targetCode) + ' notification deactivated.';
      dispatch(addToast({
        bg: 'bg-success',
        msg: msg,
        id: -1
      }))
    }).catch(e=>{
      addToast({
        bg: 'bg-danger',
        msg: Constants.ERROR_NOTIFICATION,
        id: -1
      })
    })
  }, [dispatch, notifications, targetCode]);

  const addNotify = useCallback(async ():Promise<any> =>{
    if (!userState.loggedIn) {
      setPendingNotify(true);
      setLoginPromptModal(true);
    } else {
      setPendingNotify(false);
      await NotificationService.createNotification({
        targetCode: targetCode.toUpperCase(),
        targetType: type.toUpperCase()
      }).then(response =>{
        const msg: string = Utils.toTitleCase(targetCode) + ' notification active.';
        dispatch(addToast({
          bg: 'bg-success',
          msg: msg,
          id: -1
        }))
        dispatch(addNotification(response[0]));
      }).catch( e =>{
        addToast({
          bg: 'bg-danger',
          msg: Constants.ERROR_NOTIFICATION,
          id: -1
        })
      });
    }
  }, [dispatch, targetCode, type, userState.loggedIn]);

  const handleToggle = useCallback(async ():Promise<any> => {
    if (notifyOn){
      removeNotify()
    } else {
      addNotify();
    }
  }, [notifyOn, removeNotify, addNotify])

  useEffect(()=> {
    if (pendingNotify && userState.loggedIn) {
      handleToggle()
    }
  }, [userState.loggedIn, pendingNotify, handleToggle]);

  return (
    <div className={'toggle-notify align-bottom d-inline-block text-end' + (notifyOn? ' notifyOn' : '')}>
      <OverlayTrigger overlay={
        <Tooltip >
          Enable/disable to be notified of new updates to documents in the {Utils.toTitleCase(searchState.industry)} industry.
        </Tooltip>
      }>
        <Form>
          {/* todo: jain - use Custom Switch same as setting here */}
          <Form.Check
            type="switch"
            id="custom-switch"
            label={notifyOn?"On":"Off"}
            checked={notifyOn}
            onChange={()=>{}}
            onClick={e=>handleToggle()}
          />
        </Form>
      </OverlayTrigger>

      {loginPromptModal && <LoginPromptModal
        title={'Activate Notification'}
        show={loginPromptModal}
        onHide={()=>{
          setPendingNotify(false);
          setLoginPromptModal(false)
        }}/>}
    </div>
  )
}
export default ToggleNotify;
