import './styles.scss';
import React, {useContext, useEffect, useState} from "react";

/* Types, Constants, Utils */
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";
import * as Constants from "../../../constants";

/* Bootstrap */

/* Redux */
import {useSelector} from "react-redux";
import {TSavedDocument, ISavedState} from "../../../types/redux/saved";
import {RootState} from "../../../redux";

/* Components */
import TagsModal from "../../tags-notes/tags/tag-modal";
import Tags from "../../tags-notes/tags/tags";
import Note from "../../tags-notes/notes";
import Save from "../../toolbars/components/save";

const ViewerTagsNotes = ():JSX.Element => {

  /* redux */
  const saveState: ISavedState = useSelector<RootState, ISavedState>(state => state.saved);

  /* context */
  const document: any = useContext<IViewerRecordContext>(ViewerRecordContext).viewerRecord;

  /* state */
  const [savedDocumentData, setSavedDocumentData] = useState<TSavedDocument | null>(null);
  const [dateSaved, setDateSaved] = useState<string>();

  useEffect(()=>{
    if (!document) return;

    const savedDocumentData: TSavedDocument | undefined = saveState.documents.find(savedDocument => savedDocument.documentId === document.id);
    setSavedDocumentData( savedDocumentData ? savedDocumentData : null)
    if (savedDocumentData) {
      setDateSaved(new Date(savedDocumentData.dateCreated).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}))
    }

  }, [saveState.documents, document])

  return  (
    <div className={'viewer-saved position-relative px-2 mt-2'}>
      {/* Note */}
      <div className={'doc-note doc-content mb-4 mt-4'}>
        <Note context={Constants.DOCUMENT}/>
      </div>

      {/* Add to Library */}
      <Save context={Constants.DOCUMENT}/>

      {/* Tags modal */}
      <div className={'doc-tags mt-2'}>
        <TagsModal appliedTags={savedDocumentData?.tags}/>
      </div>

      {/* Tags display */}
      {(savedDocumentData !== null) &&
        (savedDocumentData.tags.length > 0) &&
        <div className={'pt-2'}>
          <Tags appliedTags={savedDocumentData?.tags}/>
        </div>}

      {dateSaved && <div className={'text-secondary mt-3 text-smaller'}>Saved on {dateSaved}</div>}

      {/*{(!userState.loggedIn) &&
        <div>
          <div className={'my-5 text-center'}>
            <h6 className={'p-2'} >You must be logged in for this feature.</h6>
            <button-link variant="primary" onClick={()=>dispatch(setLoginModalActive(true))}>Log In or Register</button-link>
          </div>
        </div>}*/}
    </div>
  )
}
export default ViewerTagsNotes;
