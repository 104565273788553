import './styles.scss';
import React, {useEffect, useState} from 'react';

/* Constants, Utils, Types */
import * as Constants from "../../../../../constants";
import {Industry} from "../../../../../api/payload/payload-types";

/* API */
import {useQuery} from "react-query";
import {fetchPostByIndustry} from "../../../../../api/payload/fetchPostByIndustry";

/* Redux */
import {IContentState} from "../../../../../types/redux/content";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux";

/* Components */
import NewsPost from "../../news/post";

export const NewsFeedBlock: React.FC<
  {
    fields: any,
    id?: string
  }
> = props => {
  const {
    fields,
  } = props

  const { REACT_APP_PROD_HOST} = process.env;
  const collection: string = (window.location.host === REACT_APP_PROD_HOST) ? Constants.POSTS : Constants.STAGE_POSTS;
  const [newsPost, setNewsPost] = useState<any[] | null>(null);
  const industries: Industry[] = useSelector<RootState, IContentState>((state: RootState):any => state.content).industries;

  /* Feed */
  const { isSuccess,data} = useQuery<any, Error>(
    ['content_news-feed_' + fields.industry],
    async ({signal}) => {
      let industriesIds: string[];
      const industryName: string = industries.find(industry =>  industry.id === fields.industry).title;
      if (industryName === Constants.ALL_INDUSTRIES) {
        industriesIds = industries.map(i=> i.id)
      } else {
        industriesIds = [fields.industry];
      }
      return await fetchPostByIndustry({industries: industriesIds, collection, page:1});

    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  useEffect(()=> {
    if (isSuccess) {
      setNewsPost(data.docs);
    }
  }, [isSuccess, data])

  const posts = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    for (let i=0; i < Math.min(fields.numOfPosts, newsPost.length); i++) {
      elements.push(<NewsPost post={newsPost[i]} index={i} key={i}/>)
    }
    return elements;
  }

  return (
    <div className={'news-feed-block'}>
      {newsPost && <div className={'posts'}>
        {posts()}
      </div>}
    </div>
  )
}
