import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";
import {IMyLibraryMenuItem} from "../../../views/my-library/components/my-library-menu/interface";

/* Bootstrap */
import {Dropdown} from "react-bootstrap";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {addToast} from "../../../redux/slices/toast-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {IUserState} from "../../../types/redux/user";
import {setLoggedIn} from "../../../redux/slices/user-slice";
import {IMyLibraryState} from "../../../types/redux/my-library";
import {setLibrarySection} from "../../../redux/slices/my-library-slice";

/* Hooks */
import {useNavigate} from "react-router-dom";

/* Api */
import LoginRegistrationService from "../../../api/login-registration";

/* Hooks */
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {setFiltersKey} from "../../../redux/slices/search-slice";

import {clearTags} from "../../../redux/slices/tags-notes-slice";
import {clearAllSaved} from "../../../redux/slices/saved-slice";
import {SETTINGS} from "../../../constants";
import useSearchReset from "../../../hooks/useSearchReset";

interface IProps {
  opened(value: boolean):any;
}

const MyLibraryDropdown = (props: IProps):JSX.Element => {
  const {
    opened
  } = props;

  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const section: string | null = useSelector<RootState, IMyLibraryState>(state => state.my_library).section;
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const navigate: NavigateFunction = useNavigate();
  const [items, setItems] = useState<IMyLibraryMenuItem[]>([]);

  const searchReset: Function = useSearchReset();

  const removeActive = (): void =>{
    const menuItems = document.querySelectorAll('.my-library-dropdown a');
    if (menuItems.length > 0) {
      menuItems.forEach((node:any) => node.classList.remove(Constants.ACTIVE))
    }
  }

  useEffect(()=>{
    const menuItems: IMyLibraryMenuItem[] = [...Constants.MY_LIBRARY_MENU_ITEMS];
    menuItems.push(
      {
        label: Utils.toTitleCase(SETTINGS),
        section: Constants.SETTINGS
      }
    );
    setItems(menuItems)
  }, [setItems])

  useEffect(()=>{
    /* update dropdown if menu was used to navigate */
    removeActive();

    if (section){
      const menuItem: Element | null = document.querySelector('.' + section + '_item');
      if (menuItem) menuItem.classList.add(Constants.ACTIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section])

  const handleLogout = ():void=>{
    dispatch(setLoggedIn(false));
    dispatch(addToast({
      bg: 'bg-success',
      msg: "You are no longer logged in.",
      id: -1
    }))
    dispatch(setLibrarySection(null));

    LoginRegistrationService.logout().then(response =>{
      dispatch(clearTags());
      dispatch(clearAllSaved())
    }).catch(e =>{
      /* not a concern since will not affect re-login */
    })
  }

  const handleSelect = (key: string | null): void =>{
    if (key === Constants.LOGOUT) {
      handleLogout();
    } else if (key === Constants.LOGIN) {
      dispatch(setLibrarySection(key))
      navigate('/' + key)
    } else if (key === Constants.SETTINGS) {
      navigate('/' + Constants.MY_LIBRARY + '/' + Constants.SETTINGS + '#' + Constants.MY_ACCOUNT)
    } else {
      /* My Library */
      searchReset();
      dispatch(setLibrarySection(key))
      dispatch(setFiltersKey(Constants.TAGS))
      navigate('/' + Constants.MY_LIBRARY + '?' + Constants.MY_LIBRARY_SECTION_KEY + '=' + key)
    }
  }

  return (
    <Dropdown
      className={"my-library-dropdown position-relative"}
      autoClose={true}
      onToggle={isOpened => opened(isOpened)}
      onSelect={(eventKey: string | null)=>handleSelect(eventKey)}
    >
      <Dropdown.Toggle className={"bg-transparent border-0 p-0 dropdown-button-light"}>
        My Library
      </Dropdown.Toggle>

      <Dropdown.Menu className={'dropdown-menu font-xnormal dropdown-menu-light'}>
        {items.map((item:IMyLibraryMenuItem, index:number) => {
          return (
            <Dropdown.Item
                className={'px-3 ' + (item.section) + '_item'}
                href={''}
                key={"mldd"+index}
                eventKey={String(item.section)}
            >
              {Utils.toTitleCase(item.label)}
            </Dropdown.Item>
          )
        })}

        {/* Login */}
        {!userState.loggedIn &&
          <Dropdown.Item
            href={''}
            eventKey={Constants.LOGIN}
            className={'px-3'}
          >
          {Utils.toTitleCase(Constants.LOGIN)}
        </Dropdown.Item>}

        {/* Logout */}
        {userState.loggedIn &&
          <Dropdown.Item
            href={''}
            eventKey={Constants.LOGOUT}
            className={'px-3'}
          >
          {Utils.toTitleCase(Constants.LOGOUT)}
        </Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MyLibraryDropdown;
