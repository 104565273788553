import './styles.scss';
import React, {useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap */
import {Button, Container} from "react-bootstrap";

/* Redux */
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {TSavedDocumentTag} from "../../../../types/redux/saved";
import {IUserState} from "../../../../types/redux/user";

/* Components */
import LoginPromptModal from "../../../modal/login-prompt-modal";
import TagsApply from "../tags-apply";
import TagRemove from "../tag-remove";
import TagEdit from "../tag-edit";
import { TagsModalContext } from '../../../../types/saved';

interface IProps {
  appliedTags: TSavedDocumentTag[] | undefined
}

const TagsModal = (props: IProps):JSX.Element => {
  const {
    appliedTags
  } = props;

  /* redux state */
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);

  /* state */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<string>(Constants.APPLIED_TAGS);
  const [selectedTag, setSelectedTag] = useState<TSavedDocumentTag | null>( null);
  const [loginPromptModal, setLoginPromptModal] = useState<boolean>(false);

  /* refs */
  const modalRef:React.MutableRefObject<any> = useRef();
  const openButtonRef:React.MutableRefObject<any> = useRef();
  const openLinkRef:React.MutableRefObject<any> = useRef();

  useEffect(()=> {
    const mouseDownListener = (e: MouseEvent): void => {
      if (modalRef.current &&
        !modalRef.current.contains(e.target) &&
        !openLinkRef.current.contains(e.target) &&
        !openButtonRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    }
  },[])

  const handleToggleModal = (e: React.MouseEvent)=> {
    e.stopPropagation();
    if (!userState.loggedIn) {
      setLoginPromptModal(true);
    } else {
      setIsOpen(!isOpen)
    }
  }

  const onClose = (): void => {
    if (modalState === Constants.APPLIED_TAGS) {
      setIsOpen(false);
    } else {
      setModalState(Constants.APPLIED_TAGS)
    }
  }

  const view = ():JSX.Element => {
    switch (modalState){
      case Constants.APPLIED_TAGS:
        return <TagsApply
          appliedTags={appliedTags}
          close={onClose}
        />

      case Constants.REMOVE_TAG:
        return <TagRemove
          close={onClose}
        />

      case Constants.EDIT_TAG:
        return <TagEdit
          close={onClose}
        />

      default:
        /* unused case */
        return <></>
    }
  }

  return (
    <div className={'tags-modal d-inline-block position-relative w-100'}>

      {/* Add / Edit Link */}
      <Button
        className={'add-tag-add-remove-edit-link text-normal bg-white text-dark w-100 rounded-1 text-start py-2 px-2'}
        variant={'secondary'}
        ref={openLinkRef}
        onClick={handleToggleModal}
      >
        {/* Plus sign */}
        <div
          className={'add-tag-btn border border-secondary bg-white d-inline-block me-2 rounded-1'}
          ref={openButtonRef}
          onClick={handleToggleModal}
        >
          <i className={'bi bi-plus text-dark bg-white'}/>
        </div>
        Add/Edit a Tag
      </Button>

      {/* Dialog */}
      {isOpen &&
        <Container
          ref={modalRef}
          className={'add-edit-dialog shadow text-dark position-absolute bg-white pt-1 pb-3 px-4'}
        >
          <TagsModalContext.Provider value={
            { modeState: modalState,
              setModalState: setModalState,
              selectedTag: selectedTag,
              setSelectedTag: setSelectedTag }
          }>
            {/* View - Apply, Remove, Edit */}
            {view()}
          </TagsModalContext.Provider>
      </Container>}

      {loginPromptModal && <LoginPromptModal
          title={'Tag ' + Utils.toTitleCase(Constants.DOCUMENT)}
          show={loginPromptModal}
          onHide={()=>setLoginPromptModal(false)}/>}
    </div>
  )
}
export default TagsModal;
