import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";

/* Redux */
import {useDispatch, useSelector} from "react-redux";
import {ISearchState} from "../../../types/redux/search";
import {RootState} from "../../../redux";

/* Bootstrap */
import {Button} from "react-bootstrap";
import {TFilterOption} from "../../../types/search";
import {addFilters, removeFilters, setFilters} from "../../../redux/slices/search-slice";
import {AnyAction} from "@reduxjs/toolkit";
import LinkButton from "../../button-link";
import SearchUtils from "../../../utils/search-utils";

const FiltersApplied = ():JSX.Element => {
  /* redux */
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);

  /* state */
  const [filterButtons, setFilterButtons] = useState<TFilterOption[]>([])

  useEffect(()=>{
    const visibleFilters: TFilterOption[] = [];
    searchState.applied_filters.forEach(filter =>{
      if (!filter.invert) {
        visibleFilters.push(filter);
      }
    })

    SearchUtils.defaultFilters(searchState.db_set).inverted.forEach(filter=>{
      if (searchState.applied_filters.findIndex(af=>af.id === filter.id) === -1) {
        /* add if NOT applied */
        visibleFilters.push(filter);
      }
    })

    setFilterButtons(visibleFilters);
  }, [searchState.applied_filters, searchState.db_set])


  const handleReset = (): void => {
     dispatch(setFilters(SearchUtils.defaultFilters(searchState.db_set).default))
  }

  const removeFilter = (filterOption: TFilterOption): void => {
    if (filterOption.invert) {
      dispatch(addFilters([filterOption]))
    } else {
      dispatch(removeFilters([filterOption]))
    }
  }

  return (
    <div className={'filters-applied d-flex flex-wrap py-1 pb-lg-0 pt-lg-2'}>
      {filterButtons.map(filterOption=>{
        return (
          <div className={'btn-container rounded bg-grey-lighter me-2 position-relative text-smaller d-flex mt-1 mb-2 my-lg-0'}  key={'sl_' + filterOption.id }>
            <p className={'name ellipse'}>{(filterOption.label === Utils.toTitleCase(Constants.SELECT_ALL)) ? filterOption.value : filterOption.label}</p>
            <Button
              className={'close-btn border-0 btn-icon-only'}
              onClick={()=>removeFilter(filterOption)}
            >
              <i className={'ms-1 mbi bi-x text-black'}/>
            </Button>
          </div>
        )
      })}

      {/* Reset */}
      {(filterButtons.length > 0) &&
        <div className={'mt-2'}>
          <LinkButton
            label={'Clear All'}
            underline={true}
            clickFunc={handleReset}
          />
        </div>}

     {/* <Button className={'btn-link link-underline-none p-0 text-xnormal'} onClick={handleReset}>
        Clear All
      </Button>*/}
    </div>
  )
}
export default FiltersApplied;
