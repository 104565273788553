import './styles.scss';
import React, {Dispatch, useContext, useEffect, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import Utils from "../../../utils";
import {IResultsState} from "../../../types/redux/result";
import SearchUtils from "../../../utils/search-utils";

/* Bootstrap */
import {Button,  Col, Container, Row} from "react-bootstrap";

/* Redux */
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {ISearchState} from "../../../types/redux/search";
import {addToSearchHistory, updateSavedBibliographies, setSavedDocuments} from "../../../redux/slices/saved-slice";
import {clearResults, setResults} from "../../../redux/slices/result-slice";
import {IUserState} from "../../../types/redux/user";

/* Components */
import SearchInput from "../search-input";
import DocumentCard from "../../cards/document-card";
import Spinner from "../../util/spinner";
import BibliographyCard from "../../cards/bibliography-card";
import CollectionCard from "../../cards/collection-card";
import PageToolsTop from "../../toolbars/page-tools-top";

/* Api */
import {useQuery} from "react-query";
import SearchService from '../../../api/search';
import {IPageContext, PageContext} from "../../../types/app";
import SavedService from "../../../api/saved";

/* hooks */
import {useSearchParams} from "../../../hooks";

/* Components */
import ExpandAll from "../../toolbars/expand-all";
import PageToolsBottom from "../../toolbars/page-tools-bottom";
import SearchAdvanced from "../search-advanced";
import FiltersApplied from "../../filters/filters-applied";
import FiltersSlideOut from "../../filters/filters-slideout";

const Search = ():JSX.Element => {
  const pageContext: string = useContext<IPageContext>(PageContext).context;

  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const resultsState: IResultsState = useSelector<RootState, IResultsState>(state => state.result);
  const userState: IUserState = useSelector<RootState, IUserState>(state => state.user);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const [advancedSearchActive, setAdvancedSearchActive] = useState<boolean>(searchParams.get(Constants.ADV_KEY) === 'true');
  const [noSearchResults, setNoResults] = useState<boolean>(false);
  const [prevSearch, setPrevSearch] = useState<ISearchState>({...searchState});
  const {currentSearchParams} = useSearchParams();

  const {data, isSuccess, isError, error, isFetched} = useQuery<any, Error>(
    [JSON.stringify(searchState)],
    async ({signal}) => {
      const isFacetQuery: boolean = false;
      switch (searchState.db_set) {
        case Constants.DOCUMENTS:
          return await SearchService.solrDocumentQuery(searchState, signal, isFacetQuery);

        case Constants.BIBLIOGRAPHY:
          return await SearchService.solrBibliographyQuery(searchState, signal, isFacetQuery);
      }
    },
    {
      staleTime: Infinity
    }
  );

  /* Make request to backend to see if any records in results are saved to set the save/un-save button */
  const updateSavedState = (recordIds: string[]):void => {
    switch (searchState.db_set) {
      case Constants.DOCUMENTS:
        /* Documents */
        SavedService.getSavedDocumentsViaIds(recordIds).then((response:any) => {
          dispatch(setSavedDocuments(response.savedDocument));
        }).catch(e => handleError(e));
        break;

      case Constants.BIBLIOGRAPHY:
        /* Bibliographies */
        SavedService.getSavedBibliographiesViaIds(recordIds).then((response:any) => {
          dispatch(updateSavedBibliographies(response.savedBiblio));
        }).catch(e => handleError(e));
        break;
    }
  }

  useEffect(()=> {
    dispatch(clearResults()); // remove last results
    setNoResults(false);

    if (isSuccess && data) {
      //console.log('> Searched with filters:  ' + JSON.stringify(searchState.applied_filters.map(f=>f.id)))
      const count: number = data.response.docs.length;

      if (!SearchUtils.isDefaultQuery(searchState.query) && (JSON.stringify(searchState) !== JSON.stringify(prevSearch))) {
        setPrevSearch({...searchState})
        /* add search to url for linking and reload */
        window.history.pushState(null, '', "?" + currentSearchParams().toString());
        if (searchState.db_set === Constants.DOCUMENTS) {
          /* Add to recent searches */
          dispatch(addToSearchHistory({
            id: -1,
            dateCreated: '',
            searchDateTime: new Date().toISOString(),
            count: data.response.numFound,
            url: window.location.href
          }))
        }
      }

      if (count > 0) {
        dispatch(setResults({data:data, db_set:searchState.db_set}));
        const recordIds: string[] = data.response.docs.map((record: any) => record.id);
        updateSavedState(recordIds);
      } else {
        dispatch(clearResults());
        setNoResults(true);
      }

    } else if (isError) {
      handleError(error?.message)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError, dispatch, setNoResults, data, searchState.query, searchState.applied_filters,  pageContext, error?.message])

  useEffect(()=>{
    /* if previous logged out and logged back in, updates saveState for results rendered */
    if (userState.loggedIn && isFetched) {
      const recordIds: string[] = resultsState.results.map(result => result.id);
      updateSavedState(recordIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.loggedIn])

  useEffect(()=>{
    if (searchState.db_set !== Constants.DOCUMENTS) {
      setAdvancedSearchActive(false)
    }
  }, [searchState.db_set, setAdvancedSearchActive])

  /* todo: jain - display user friendly error in dialog */
  const handleError = (msg: string) => {
    switch (msg) {
      case Constants.INVALID_QUERY_ERROR:
        break;

      case Constants.DATE_ERROR:
        break;

      case Constants.SLOW_QUERY_ERROR:
        /* todo: jain LEGACY */
        alert("query 'bates:*' is extremely slow running.  Please use at lease one character in bates wildcard search-cmp (ex: 'bates:a*' or 'bates:1*').");
        break;
    }
  }

  const handleNoDocuments = (): JSX.Element => {
    let elem: JSX.Element;
    if (noSearchResults) {
      /* done loading with no results */
      elem = <div className={'mt-4 text-center'}>
        <p className={'p-2'} >No results found.</p>
      </div>
    } else if (isError) {
      /* Error messaging  */
      elem = <div className={'mt-4 text-center'}/>
    } else {
      /* loading */
      elem = <div className={'mt-4 text-center'}>
        <Spinner size={Constants.MEDIUM}/>
      </div>
    }
    return elem;
  }

  const getCard = (dbSet: string | null, record: any, index: number): JSX.Element => {
    let card: JSX.Element = <></>;
    switch (dbSet) {
      case Constants.DOCUMENTS:
        card = <DocumentCard
          key={`dc-${index}`}
          index={index}
          record={record}
        />
        break;

      case Constants.COLLECTIONS:
        card = <CollectionCard
          key={`dc-${index}`}
          index={index}
          record={record}
        />
        break;

      case Constants.BIBLIOGRAPHY:
        card = <BibliographyCard
          key={`dc-${index}`}
          index={index}
          record={record}
        />
        break;

      default:
        // fail case : null
        break;
    }
    return card
  }

  /* Learn More Link */
  const learnMore = ():JSX.Element =>{
    return (
      <>
        {(pageContext === Constants.INDUSTRY) &&
          (!advancedSearchActive) &&
            <div className={'info-btn search-info-link text-secondary'}>
              {/*<i className="bi bi-info-circle-fill"/>*/}
              <NavLink
                to={'/help/how-to-search/'}
                className={'ms-1 text-smaller'}
              >
                How to Search
              </NavLink>
            </div>
        }
      </>
    )
  }

  /* Advanced Search Button */
  const advanceSearchBtn = ():JSX.Element =>{
    return (
      <>
        {(searchState.db_set === Constants.DOCUMENTS) &&
          <div>
            <Button
              variant={'light'}
              id={'search-toggle-btn'}
              className={'mt-1 bg-transparent btn-link text-smaller text-end text-nowrap pe-2'}
              onClick = {()=>setAdvancedSearchActive(!advancedSearchActive)}
            >
              {advancedSearchActive ? 'Simple Search' : 'Advanced Search'}
            </Button>
          </div>}
      </>
    )
  }

  return (
    <div className={'search'}>
      <Container fluid={true} className={"px-0 px-lg-3 mt-0 mt-lg-2 pb-5 bg-white rounded-md border"}>

        {/* mobile */}
        <Container className={"d-lg-none p-0 bg-white"}>
          <Row className={'search-container p-0 m-0  pt-3'}>
            <SearchInput
              advSearch={advancedSearchActive}
              minimal={false}
              fieldSelect={true}
            />

            <Row className={'m-0 mt-2 mb-2'}>
              <Col className={'mb-1 p-0'}>
                <FiltersSlideOut label={'Filters'}/>
              </Col>

              <Col className={'text-end'}>
                {advanceSearchBtn()}
              </Col>
            </Row>

            {advancedSearchActive &&
              <Row className={'p-0 m-0'}>
                <SearchAdvanced/>
              </Row>
            }
          </Row>

          <Row className={'p-0 m-0'}>
            <FiltersApplied/>
          </Row>

          {/* Page tools */}
          <PageToolsTop/>

        </Container>

        {/* desktop */}
        <Container className={"d-none d-lg-block p-0 m-0 mt-4"}>
          {/* Search Form Input */}
          {<Row className={'flex-nowrap align-items-top my-3'}>
            <Col lg={10}>
              <SearchInput
                advSearch={advancedSearchActive}
                minimal={false}
                fieldSelect={true}
              />
              {advancedSearchActive && <SearchAdvanced/>}
            </Col>

            <Col lg={2} className={'me-3 p-0 text-end pe-5'}>
              {advanceSearchBtn()}
            </Col>
          </Row>}

          {/* Learn More Link */}
          <Row className={'mx-0 my-4 w-100 text-center'}>{learnMore()}</Row>

          {/* Page tools */}
          <PageToolsTop/>

          <div className={'mb-3 mt-2'}>
            <FiltersApplied/>
          </div>

        </Container>

        <Row className={'px-3 px-lg-1 pt-0 pb-2 mt-2 mx-0'}>
          {/* Num of Results */}
          <Col className={'p-0 '}>
            {(resultsState.results.length > 0) &&
              <div className={'text-grey text-small'}>
                {resultsState.searchResultMeta && Utils.formatNumber(resultsState.searchResultMeta.num_found)}
                <span>&nbsp;results</span>
              </div>
            }
          </Col>

          {/* Expand all records */}
          {((searchState.db_set === Constants.DOCUMENTS) ||
              (searchState.db_set === Constants.COLLECTIONS)) &&
            <Col className={'p-0 text-end'}>
              <ExpandAll/>
            </Col>
          }
        </Row>

        {/* Results */}
        <Row className={'mx-0'}>
          {/* if no results */}
          {!resultsState.results.length &&
            handleNoDocuments()
          }

          {/* if results */}
          {resultsState.results.map((result, index) => {
              return getCard(searchState.db_set, result, index)
            })
          }
        </Row>

        {/* Page tools bottom */}
        {(resultsState.results.length > 0) &&
          <PageToolsBottom/>
        }
      </Container>
    </div>
  )
}

export default Search;
