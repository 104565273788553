import {useEffect, useState} from "react";
import * as Constants from "../constants";

export interface IUsePlatform {
  isMobile: boolean;
  isDesktop:boolean;
}

const usePlatform = (): IUsePlatform => {
  const [isMobile, setIsMobile ] = useState<boolean>(false);

  useEffect(()=>{
    const bootstrapBreakpoint = ():string => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 576) {
        return 'xs';
      } else if (windowWidth >= 576 && windowWidth < 768) {
        return 'sm';
      } else if (windowWidth >= 768 && windowWidth < 992) {
        return 'md';
      } else if (windowWidth >= 992 && windowWidth < 1200) {
        return 'lg';
      } else if (windowWidth >= 1200 && windowWidth < 1400) {
        return 'xl';
      } else {
        return 'xxl';
      }
    }

    const mobileDim = ():boolean =>{
      const breakPoint: string = bootstrapBreakpoint();
      return ((breakPoint === 'xs') || (breakPoint === 'sm') || (breakPoint === 'md') );
    }

    setIsMobile(mobileDim())
    window.addEventListener(Constants.RESIZE, (event) => setIsMobile(mobileDim()), true);

    return ()=>{
      window.removeEventListener(Constants.RESIZE, (event) => setIsMobile(mobileDim()), true);
    }
  }, [])

  return {
    isMobile,
    isDesktop: !isMobile
  }
};

export default usePlatform;
