import './styles.scss';
import React from "react";

/* Bootstrap */
import {Modal} from "react-bootstrap";

/* Components */
import ReportIssue from "../../report-issue";

interface IProps {
  defaultSelection?: string;
  show: boolean;
  onHide(): void;
}

const REPORT_ISSUE_TITLE: string = "Report an Issue";

const ReportIssueModal = (props: IProps):JSX.Element => {
  return (
    <Modal
      {...props}
      keyboard={false}
      centered
      className={"report-issue-modal"}
    >
      <Modal.Header closeButton className={'border-0 pb-0'}>
        <Modal.Title className={'fs-5'}>{REPORT_ISSUE_TITLE}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={'pb-4'}>
        <ReportIssue defaultSelection={props.defaultSelection}/>
      </Modal.Body>
    </Modal>
  )
}
export default ReportIssueModal;
