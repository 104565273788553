import  './styles.scss'
import * as Constants from "../../../../../constants";
import React, {Dispatch, useEffect} from 'react'


import {Container} from 'react-bootstrap';

import {Card} from "../Card";
import {IContentState} from "../../../../../types/redux/content";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux";
import {Category} from "../../../../../api/payload/payload-types";
import Utils from "../../../../../utils";
import {removeToCrumb} from "../../../../../redux/slices/breadcrumb-slice";
import {IBreadCrumbState} from "../../../../../types/redux/breadcrumb";
import {AnyAction} from "@reduxjs/toolkit";

export const CardMenuGrid: React.FC<
  {
    fields: any,
    id?: string
  }
> = props => {
  const {
    fields
  } = props

  const contentCategory: Category = useSelector<RootState, IContentState>((state: RootState):any => state.content).category;
  const breadcrumbState: IBreadCrumbState = useSelector<RootState, IBreadCrumbState>(state => state.breadcrumb);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  useEffect(()=>{
    dispatch(removeToCrumb(breadcrumbState.breadcrumbs[1]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch]);

  const sanitizeAttributes = (attrs: any): any => {
    const sanitizedAttr: any = {};
    for (let key in attrs) {
      sanitizedAttr[key.toLowerCase()] = attrs[key]
    }
    return sanitizedAttr
  }

  return (
    <div className={'card-menu-grid p-0 pb-lg-5'}>
      {contentCategory &&
        <h1 className={'text-start'}>{Utils.toTitleCase(contentCategory.title)}</h1>}

      <Container>
        <div className={'cards'}>
          {fields.items.map((c: any, i: number)=>{
            return <Card key={'card' + i} type={Constants.MENU} {...sanitizeAttributes(c)}/>
          }
        )}
        </div>
      </Container>
    </div>
  )
}
