import './styles.scss';
import React, {Dispatch, useContext, useState} from "react";

/* types, constants, utils */
import Utils from "../../../utils";
import * as Constants from "../../../constants";
import {IViewerRecordContext, ViewerRecordContext} from "../../../types/document";

/* components */
import ViewerSearchList from "./viewer-record-lists/viewer-search-list";
import ViewerMoreLikeList from "./viewer-record-lists/viewer-more-like-list";
import ViewerBatesBrowseList from "./viewer-record-lists/viewer-bates-browse-list";
import ViewerDuplicatesList from "./viewer-record-lists/viewer-duplicates-list";
import {ListGroup} from "react-bootstrap";
import {setRecordTool} from "../../../redux/slices/viewer-slice";
import {AnyAction} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import usePlatform from "../../../hooks/usePlatform";

interface IProps {
  activeTool:string,
  handleToolSelect(value:string):void;
}

const ViewerRecords = (props: IProps):JSX.Element => {
  const {
    activeTool,
    handleToolSelect
  } = props;

  const viewerRecordContext: IViewerRecordContext = useContext<IViewerRecordContext>(ViewerRecordContext);
  const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
  const hasQuery: boolean = (searchParams.get(Constants.QUERY_KEY) !== null);
  const [numOfRecords, setNumOfRecords] = useState<number>(null);
  const dispatch:Dispatch<AnyAction> = useDispatch();
  const isMobile: boolean = usePlatform().isMobile;

  const handleSelect = (selectedRecord: any): void  => {
    if (!viewerRecordContext.setViewerRecord) return;
    viewerRecordContext.setViewerRecord(selectedRecord);
    searchParams.set(Constants.ID, selectedRecord.id);
    window.history.pushState(null, '', "?" + searchParams.toString());
    if (isMobile) {
      dispatch(setRecordTool(Constants.DOCUMENT))
    }
  }

  const results = ():JSX.Element => {
    let jsx: JSX.Element = <></>;
    switch (activeTool) {
      case Constants.SEARCH_RESULTS:
        if (hasQuery){
          jsx = <ViewerSearchList
            handleSelect={handleSelect}
            setNumOfRecords={setNumOfRecords}
          />
        }
        break;

      case Constants.BROWSE:
        jsx = <ViewerBatesBrowseList
          context={activeTool}
          recordId={viewerRecordContext.initialRecord?.id}
          handleSelect={handleSelect}
          setNumOfRecords={setNumOfRecords}
        />
        break;

      case Constants.POSSIBLE_DUPLICATES:
        if (viewerRecordContext.initialRecord && viewerRecordContext.initialRecord.signature) {
          jsx = <ViewerDuplicatesList
            handleSelect={handleSelect}
            setNumOfRecords={setNumOfRecords}
          />
        }
        break;

      case Constants.BATES:
        /* if bates not available do not display tab */
        if (viewerRecordContext.initialRecord.bn) {
          jsx = <ViewerBatesBrowseList
            context={activeTool}
            recordId={viewerRecordContext.initialRecord.id}
            handleSelect={handleSelect}
            setNumOfRecords={setNumOfRecords}
          />
        }
        break;

      case Constants.MORE_LIKE_THIS:
        jsx = <ViewerMoreLikeList
          handleSelect={handleSelect}
          setNumOfRecords={setNumOfRecords}
        />
        break;

      default:
        /* no case */
    }

    return jsx;
  }

  const recordCardMenu = ():JSX.Element =>{
    return (
      <ListGroup as="ul" className={'d-block flex-unset px-3 pt-4 pb-2 border-bottom border-top'}>
        {/* Search */}
        <ListGroup.Item
          action as="li"
          className={(activeTool === Constants.SEARCH_RESULTS) ? ' active': ''}
          onClick={()=>handleToolSelect(Constants.SEARCH_RESULTS)}
        >
          <div><strong>{Utils.toTitleCase(Constants.RECORDS)}</strong></div>
        </ListGroup.Item>

        {/* Browse */}
        <ListGroup.Item
          action as="li"
          className={(activeTool === Constants.BROWSE) ? ' active': ''}
          onClick={()=>handleToolSelect(Constants.BROWSE)}
        >
          <div><strong>{Utils.toTitleCase(Constants.BROWSE)}</strong></div>
        </ListGroup.Item>


        {/* Duplicates */}
        {(viewerRecordContext.initialRecord && viewerRecordContext.initialRecord.signature) &&
          <ListGroup.Item
            action as="li"
            className={(activeTool === Constants.POSSIBLE_DUPLICATES) ? ' active': ''}
            onClick={()=>handleToolSelect(Constants.POSSIBLE_DUPLICATES)}
          >
            <div><strong>{Utils.toTitleCase(Constants.POSSIBLE_DUPLICATES)}</strong></div>
          </ListGroup.Item>}

        {/* Bates */}
        {(viewerRecordContext.initialRecord.bn) &&
          <ListGroup.Item
            action as="li"
            className={(activeTool === Constants.BATES) ? ' active': ''}
            onClick={()=>handleToolSelect(Constants.BATES)}
          >
          <div><strong>{Utils.toTitleCase(Constants.BATES)}</strong></div>
        </ListGroup.Item>}

        {/* More like this */}
        <ListGroup.Item
          action as="li"
          className={(activeTool === Constants.MORE_LIKE_THIS) ? ' active': ''}
          onClick={()=>handleToolSelect(Constants.MORE_LIKE_THIS)}
        >
          <div><strong>{Utils.toTitleCase(Constants.MORE_LIKE_THIS)}</strong></div>
        </ListGroup.Item>
      </ListGroup>
    )
  }

  return (
    <div className={'viewer-records position-relative mt-4'}>
      <div className={'d-lg-none bg-white'}>
        {viewerRecordContext.initialRecord && recordCardMenu()}
      </div>

      {/* Num of Results */}
      {(numOfRecords !== null) &&
        <div className={'border-bottom pb-2'}>
          <p className={"ps-3 mb-0 mt-2"}>
            {Utils.formatNumber(numOfRecords)}
            <span>&nbsp;results</span>
          </p>
        </div>}

      { /* Results*/ }
      { results() }
    </div>
  )
}
export default ViewerRecords;
