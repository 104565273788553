import './styles.scss';
import React, {Dispatch, useEffect, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../../../constants";
import Utils from "../../../../../../utils";

/* Redux */
import {IContentState, IDocumentCountItem, IDocumentCounts} from "../../../../../../types/redux/content";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../redux";
import {AnyAction} from "@reduxjs/toolkit";
import {setDocumentCounts, setReleaseDate} from "../../../../../../redux/slices/content-slice";

/* API */
import ContentService from "../../../../../../api/content";

/* Third party */
import {useQuery} from "react-query";

interface IProps {
  node: any;
  index: number;
}

const DocumentCount = (props: IProps):JSX.Element => {
  const {
    node,
    index
  } = props;

  const dispatch: Dispatch<AnyAction> = useDispatch();
  const [supportingDataLoading, setSupportingDataLoading] = useState<boolean>(false);
  const documentCounts: IDocumentCounts = useSelector<RootState, IContentState>((state: RootState): any => state.content).document_counts;
  const [countItem, setItemCount] = useState<IDocumentCountItem | null>(null)

  const buildCountList = (rawCounts: any) => {
    const counts: IDocumentCountItem[] = [];
    for (let i = 0; i < rawCounts.length; i++) {
      const item: IDocumentCountItem = {
        label: rawCounts[i],
        value: rawCounts[i + 1]
      }
      counts.push(item)
      i++;
    }
    return counts;
  }

  const {refetch} = useQuery<any, Error>(
    ['documentCount'],
    async ({signal}) => {
      const bibliographyCounts: any = await ContentService.documentCountsQuery(Constants.BIBLIOGRAPHY, undefined);
      const bibliography: IDocumentCountItem[] = buildCountList(bibliographyCounts.facet_counts.facet_fields.industry_facet);

      const collectionsCount: any = await ContentService.documentCountsQuery(Constants.COLLECTION, undefined);
      const collection: IDocumentCountItem[] = buildCountList(collectionsCount.facet_counts.facet_fields.collection_facet);

      const videosCount: any = await ContentService.documentCountsQuery(Constants.VIDEO, undefined);
      const videoByIndustry: any = videosCount.facet_counts.facet_fields.industry_facet;
      const video: IDocumentCountItem[] = buildCountList(videoByIndustry);

      let industry: IDocumentCountItem[] = [];
      let pages: IDocumentCountItem[] = [];
      try {
        /* Documents per industries */
        const industriesCount: any = await ContentService.documentCountsQuery(Constants.INDUSTRIES_DOCUMENTS, undefined);
        industry = buildCountList(industriesCount.facet_counts.facet_fields.industry_facet);
        let allIndustriesTotal = 0;
        industry.forEach(dc => allIndustriesTotal += Number(dc.value))
        industry.push({
          label: Utils.toTitleCase(Constants.ALL_INDUSTRIES),
          value: String(allIndustriesTotal)
        })
      } catch (e) {
        console.error('Industry document count failed')
      }

      try {
        /* Pages Per Industry */
        const industriesPageCount: any = await ContentService.documentCountsQuery(Constants.INDUSTRIES_PAGES, undefined)
        pages = [];
        const industryFacet: any = industriesPageCount.stats.stats_fields.pg.facets.industry_facet;
        let allIndustriesTotal = 0;
        let value: number;
        for (let key in industryFacet) {
          value = Math.round(industryFacet[key].sum)
          allIndustriesTotal += value;
          pages.push({
            label: key,
            value: String(value)
          })
        }
        pages.push({
          label: Utils.toTitleCase(Constants.ALL_INDUSTRIES),
          value: String(allIndustriesTotal)
        })
      } catch (e) {
        console.error('Industry pages count failed')
      }

      dispatch(setDocumentCounts({
        bibliography,
        collection,
        industry,
        pages,
        video
      }));

      const releaseDateQuery: any = await ContentService.latestReleaseQuery(undefined);
      const dateStr: string = new Date(releaseDateQuery.response.docs[0].ddu).toLocaleString('default', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });

      dispatch(setReleaseDate(dateStr));
      setSupportingDataLoading(false);
    },
    {
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (!supportingDataLoading && (documentCounts.industry.length === 0)) {
      setSupportingDataLoading(true);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentCounts.industry.length === 0) return;

    let label: string = node[node.record_type];
    if ((node.record_type === Constants.INDUSTRY) && (node.count_type === Constants.PAGE)) {
      label = node.industry;
    }
    const type: string = (node.count_type === 'page') ? 'pages' : 'industry';
    const item: IDocumentCountItem = documentCounts[type].find((d: IDocumentCountItem) => {
      return Utils.convertToKeyOrId(d.label) === Utils.convertToKeyOrId(label)
    });

    setItemCount(item);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentCounts.industry, node.record_type, documentCounts.pages]);

  return (
    <div className={'document-count' + (countItem? '': ' loading')} key={index}>
      {countItem &&
        <span className={'loading-indicator'}>
          {Utils.formatNumber(parseInt(countItem.value))}
        </span>
      }

      {!countItem &&
        <div className={'ellipses_animated m-0 p-0'}/>
      }
    </div>
  )
}

export default DocumentCount;
