import './styles.scss';
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../../constants";
import Utils from "../../../../utils";

/* Bootstrap */
import {Button, Form, Modal, Pagination} from "react-bootstrap";

interface IProps {
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number | null;
  page: number;
}

const Paging = (props: IProps):JSX.Element => {
  const {
    setPage,
    totalPages,
    page
  } = props;

  const [pageInput, setPageInput] = useState<string>(String(page));
  const inputRef = useRef(null);
  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false);

  useEffect(()=>{
    setPageInput(String(page));
  }, [page]);

  const handleKeydown = (e: any): void => {
    const pgNum: number = parseInt(pageInput);
    if (e.key === Constants.ENTER) {
      e.preventDefault();
      e.stopPropagation();

      if (pgNum > totalPages) {
        setModalDisplayed(true)
      } else {
        setPage(pgNum)
      }
    }
  }

  const handleFocus = (elem:any): void => {
    elem.setSelectionRange(0, elem.value.length)
    elem.classList.add("float-top")
  }

  const handleBlur = (elem:any): void =>{
    elem.classList.remove("float-top")
  }

  const handleNext = (): void => {
      let nextPage: number = page + 1;
      if (page <= totalPages) {
        setPage(nextPage)
      }
  }

  const disabledState = ():boolean => {
    let disabled: boolean = (totalPages === 0);
    disabled = (page === totalPages) || (totalPages === 0) || (page === 0)
    return disabled
  }

  return <div className={"paging d-inline-flex flex-row"}>
    <Pagination size="sm" className={"m-0"}>
      {/* Previous */}
      <Pagination.Item
        disabled={(page <= 1)}
        onClick={()=>setPage(page - 1)}
      >
        <div className={'arrow-down d-md-none'}/>
        <div className={'d-none d-md-block px-4 my-1'}>{Utils.toTitleCase(Constants.PREVIOUS)}</div>
      </Pagination.Item>

      {/* Page input */}
      <li>
        <Form>
          <Form.Control
            ref={inputRef}
            className={"rounded-0 border-0 border-top border-bottom text-center p-4"}
            type={"text"}
            maxLength={3}
            value={pageInput}
            onChange={e=>setPageInput(e.target.value)}
            onKeyDown={e=>handleKeydown(e)}
            onFocus={e=>handleFocus(e.target)}
            onBlur={e=>handleBlur(e.target)}
          />
        </Form>
      </li>

      {/* Next */}
      {<Pagination.Item
        onClick={()=>handleNext()}
        disabled={disabledState()}
      >
        <div className={'arrow-up d-md-none'}/>
        <div className={'d-none d-md-block px-4 my-1'}>{Utils.toTitleCase(Constants.NEXT)}</div>
      </Pagination.Item>}
    </Pagination>

    {<span className={"align-self-center mx-2 text-small hidden" + (totalPages ? "hidden" : "")}>
      of {Utils.formatNumber(totalPages)} Pages
    </span>}

    {/* Page input exceeds max pages allowed */}
    <Modal show={modalDisplayed} onHide={()=>setModalDisplayed(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Search Invalid</Modal.Title>
      </Modal.Header>

      <Modal.Body className={"border-0"}>
        The page number inputted exceeds maximum allowed. Try refining the search or filtering the results.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={()=>setModalDisplayed(false)}>
         Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
}
export default Paging;
