import './styles.scss';
import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import {Container, Nav, Navbar} from "react-bootstrap";

/* Components */
import MyLibraryDropdown from "../my-library-dropdown";
import Utils from "../../../utils";

const HeaderIDL = ():JSX.Element => {
  const [toggleActive, setToggleActive] = useState<boolean>(false);
  const navbarToggleRef:React.MutableRefObject<any> = useRef(null);
  const [active, setActive] = useState<string>(null)
  const [libraryMenuOpen, setLibraryMenuOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState(false)

  /* hooks */
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    const pathname: string = location.pathname;
    setActive(pathname.split('/').pop());
  }, [location.pathname, setActive]);

  useEffect(()=>{
    if (!libraryMenuOpen) {
      setExpanded(false)
    }
  }, [libraryMenuOpen])

  const handleToggle = ():void => {
    if (toggleActive) {
      navbarToggleRef.current.blur();
    }
    setToggleActive(!toggleActive);
  }

  const navLink = (label: string, link: string): JSX.Element => {
    const isActive: boolean = link.split('/').pop() === active;
    return <Nav.Link
        onClick={()=>setExpanded(false)}
        className={"link text-normal text-nowrap position-relative pb-0 mb-0"  + (isActive ? ' bold': '')} as={Link} to={link}
    >
      <div className={'d-inline-block'}>
        {label}
        <div className={'active_select mt-lg-3' + (isActive ? '': ' hidden')}/>
      </div>
    </Nav.Link>
  }

  return (
    <div className={'header-idl bg-white'}>
      <Navbar className={"pt-2 pt-lg-4 pb-2 pb-lg-0"} expanded={expanded}  expand="lg" onToggle={handleToggle}>
        <Container>
          <h1
            className={'d-inline-block text-black font-thin p-0 m-0 text-nowrap mb-0 mb-lg-3'}
            role={'button'}
            onClick={()=> {
              navigate('/home')
            }}
          >
            Industry Documents Library
          </h1>

          <Navbar.Toggle aria-controls={"header-ucsf-navbar-nav"} ref={navbarToggleRef} onClick={()=>setExpanded(!expanded)}/>
          <Navbar.Collapse id="header-navbar-nav" className={"justify-content-end " + (active ? 'mb-0' : 'mb-3')}>
            <Nav className={"px-1 my-lg-0"}>
              {navLink(Utils.toTitleCase(Constants.INDUSTRIES), '/' + Constants.INDUSTRIES)}
              {navLink(Utils.toTitleCase(Constants.NEWS), '/' + Constants.NEWS)}
              {navLink(Utils.toTitleCase(Constants.RESOURCES), '/' + Constants.RESOURCES)}
              {navLink(Utils.toTitleCase(Constants.ABOUT_IDL), '/' + Constants.ABOUT_IDL)}
              {navLink(Utils.toTitleCase(Constants.HELP), '/' + Constants.HELP)}
              <Nav.Link
                  onClick={e=>e.preventDefault()}
                  className={"link text-normal text-nowrap position-relative pb-0 mb-0" + (active === Utils.convertToKeyOrId(Constants.MY_LIBRARY) ? ' bold' : '')} as={Link} to={'/'}
              >
                <div className={'d-inline-block position-relative'}>
                  <MyLibraryDropdown  opened={setLibraryMenuOpen}/>
                  <div className={'active_select mt-0 mt-lg-3 ' +
                      (active === Utils.convertToKeyOrId(Constants.MY_LIBRARY) ? '': ' hidden') +
                      (libraryMenuOpen ? ' library-menu-open-select': '') }/>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  )
}

export default HeaderIDL;
