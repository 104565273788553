import './styles.scss';
import React from "react";

import {format} from "date-fns";

interface IPostLayout {
  post: any
}

const PostLayout = (props: IPostLayout):JSX.Element => {
  const {
    post
  } = props;

  return (
    <div className={'post-layout'}>
      {post.title && <h2>{post.title}</h2>}
      {post.publishedAt && <p className={'publish-date p-0'}>{format(new Date(post.publishedAt), 'EEEE, MMMM do, yyyy')}</p>}

      {post.industries &&
        <div className={'industries mb-4'}>
          {post.industries.map((industry:any, i:number) => <span key={i} className={'shadow-sm rounded border border-secondary p-2 mx-2 tag bg-' + industry.title.toLowerCase()}>{industry.title}</span>)}
        </div>}

      {/*{post.authors.map((author:string) => <p className={'title'}>{author}</p>)}*/}
    </div>
  )
}

export default PostLayout;
