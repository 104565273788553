import './styles.scss';
import React from "react";

/* Type, Constants, Utils */
import * as Constants from "../../../constants";

/* Bootstrap */
import {Card, Col, Container, Form, Row} from "react-bootstrap";

/* Components */
import CardTools from "../../toolbars/card-tools";

interface IProps {
  selected: boolean;
  handleSelected(selected: boolean): void;
  getLabel(): JSX.Element;
}

const CardHeader = (props: IProps):JSX.Element => {
  const {
    selected,
    handleSelected,
    getLabel
  } = props;

  return (
    <Card.Header className={'px-2 px-lg-3'}>
      <Container className={'px-2'}>
        <Row className={'mb-0'}>
          <Col lg={6} xl={8} >
            <Form>
              <div key={'default-checkbox'}>
                <Form.Check
                  className={'form-check mt-2 mb-0 float-start'}
                  type={'checkbox'}
                  checked={selected}
                  onChange={e=>handleSelected(e.target.checked)}
                  label={getLabel()}
                />
              </div>
            </Form>
          </Col>

          <Col lg={6} xl={4} className={'p-0 text-end'}>
            <CardTools context={Constants.CARD}/>
          </Col>
        </Row>
      </Container>
    </Card.Header>
  )
}
export default CardHeader;
