import './styles.scss';
import React, {Dispatch, useEffect, useMemo, useState} from "react";

/* Types, Constants, Utils */
import * as Constants from "../../../constants";
import {
  TAdvConstraint,
  TAdvSearchDate, TConstraint,
  TQueryItem
} from "../../../constants";

import Utils from "../../../utils";
import SearchUtils from "../../../utils/search-utils";
import {ConstraintDropdownContext, TFilter, TFilterOption} from "../../../types/search";

/* Bootstrap */
import {Button, Col, Container, InputGroup, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";

/* Redux */
import {ISearchDate, ISearchState} from "../../../types/redux/search";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux";

/* Components */
import ConstraintDropdown from "./constraint-dropdown";
import DatePicker from "./date-picker";
import {AnyAction} from "@reduxjs/toolkit";
import {addFilters, removeFilters, setQuery, setSearchDates} from "../../../redux/slices/search-slice";
import SearchButton from "../search-input/search-button";
import useInsertDBFields from "../hooks/useInsertDBFields";
import usePlatform from "../../../hooks/usePlatform";

const SearchAdvanced = ():JSX.Element => {
  const searchState: ISearchState = useSelector<RootState, ISearchState>(state => state.search);
  const dispatch:Dispatch<AnyAction> = useDispatch();

  const searchParams: URLSearchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const [constraints, setConstraints] = useState<TAdvConstraint[]>([{...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT}]);
  const [dates, setDates] = useState<TAdvSearchDate[]>([...Constants.ADV_SEARCH_DATES]);
  const [closePickers, setClosePickers] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [categoriesFilterOptions, setCategoriesFilterOptions] = useState<TFilterOption[]>([]);

  const insertDBFields = useInsertDBFields();
  const isDesktop: boolean = usePlatform().isDesktop;


  useEffect(()=> {
      const categoriesFilter: TFilter | undefined = searchState.filters_db.advance_search.find(f=>f.id===Constants.CATEGORIES);
      if (categoriesFilter) {
        setCategoriesFilterOptions([...categoriesFilter.options])
      }
  }, [searchState.filters_db])

  useEffect(()=>{
    searchParams.set(Constants.ADV_KEY, 'true');
    window.history.replaceState(null, '', "?" + searchParams.toString());

    return ()=>{
      const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
      searchParams.delete(Constants.ADV_KEY)
      window.history.replaceState(null, '', "?" + searchParams.toString());
    }
  }, [searchParams])

  useEffect(() => {
    let queryParams: string | null = '';
    const searchQuery: TQueryItem[] = searchState.query;
    for (let i=0; i<searchQuery.length; i++) {
      const qp:TQueryItem = searchQuery[i];
      queryParams += `${qp.operator},${qp.field},${qp.match},${qp.term}` + (i < searchQuery.length - 1 ? ';' : '')
    }

    const constraintsFromParams: TAdvConstraint[] = SearchUtils.searchParamsToConstraints(queryParams);
    setConstraints(constraintsFromParams);
    constraintsFromParams.forEach(c=> {
      if (c.term && c.term.length > 0) {
        setDirty(true)
      }
    });

    /* !note: search-slice capture dates from params independently, async redux results in visible redraw */
    const dateParams: string | null = searchParams.get(Constants.DATES_KEY);
    setDates(SearchUtils.dateParamToDates(dateParams));

    /* todo: re-constitute filter from URL here */
    /*const filterParams: string | null = searchParams.get(Constants.FILTERS_KEY);
    if (filterParams) {
      const filters: string[] = filterParams.split(',');
      const categoryFilters: string[] = [];
      categoriesFilterOptions.forEach((c) =>{
        if (filters.indexOf(c.id) !== -1) {
          categoryFilters.push(c.id)
        }
      });
      setCategories(categoryFilters);
    }*/

    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    const closeCalendar = (e:any):void=> {
      /* close any calendar that is open if click outside of it */
      let found:boolean = false;
      const iterator = e.target.parentElement.classList.values();

      for (const value of iterator) {
        if (value.includes('react-calendar') || value.includes('date-field-input') || value.includes('date-field-btn')) {
          found = true;
          break
        }
      }

      if (!found) {
        setClosePickers(true);
      }
    }
    document.addEventListener(Constants.MOUSEDOWN, e=> closeCalendar(e));

    return () => document.removeEventListener(Constants.MOUSEDOWN, e=> closeCalendar(e));
  }, [])

  const updateConstraints = (line: number, field: string, value: string | null): void => {
    const constraintClone: any = [...constraints]
    if (field === Constants.TERM) {
      constraintClone[line][field] = value;
    } else {
      let entry: any | null = Constants.ADV_SEARCH_DROPDOWNS[field].find((entry: TConstraint)=>{
        return entry.value === value;
      });

      /* Special case - search fields are broken out separately */
      if (!entry && (field === Constants.FIELD)) {
        entry = Constants.ADV_SEARCH_DROPDOWNS[Constants.FIELD_SEARCH].find((entry: TConstraint)=>{
          return entry.value === value;
        });
      }
      constraintClone[line][field] = {...entry};
    }
    setConstraints(constraintClone);

    setDirty(true);
  }

  const addNewLine = ()=> {
    const constraintClone: any = [...constraints]
    constraintClone.push({...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT});
    setConstraints(constraintClone);

    setDirty(true);
  }

  const updateDates = (date: any): void => {
    const pos: number = dates.findIndex((entry: any) => {
      return entry.id === date.id
    });

    const updatedDates: any[] = [...dates];
    updatedDates[pos] = {...date};
    setDates(updatedDates);

    setDirty(true);
  }

  const clear = (): void => {
    setClosePickers(true)
    setConstraints([{...Constants.ADV_SEARCH_DEFAULT_CONSTRAINT}]);
    setDates([...Constants.ADV_SEARCH_DATES]);

    /* clear categories */
    const categoriesFilterCln: TFilterOption[] = categoriesFilterOptions.map(cfo=>{
        const optionCln: TFilterOption = {...cfo};
        optionCln.selected = false;
        return optionCln;
      }
    );
    setCategoriesFilterOptions(categoriesFilterCln);

    /* reset params */
    searchParams.delete(Constants.QUERY_KEY);
    searchParams.delete(Constants.FILTERS_KEY);
    searchParams.delete(Constants.DATES_KEY);
    window.history.replaceState(null, '', "?" + searchParams.toString());

    setDirty(false);
  }

  const performQuery = (e:React.MouseEvent):void => {
    e.preventDefault();
    e.stopPropagation();

    const query: TQueryItem[] = [];
    for (let i=0; i<constraints.length; i++) {
      const queryItem: TQueryItem = {...Constants.DEFAULT_SEARCH};
      queryItem.operator = (i > 0 ? constraints[i].operator.value : null); /* first constraint no operator */
      queryItem.field = constraints[i].field.value;
      queryItem.match = constraints[i].match.value;
      queryItem.term = constraints[i].term ? constraints[i].term : '*:*';
      query.push(queryItem);
    }

    /* Query */
    searchParams.set(Constants.QUERY_KEY, SearchUtils.searchQueryToString(query));
    dispatch(setQuery({
      query: query,
      recent_query: true
    }))

    /* Filters */
    dispatch(removeFilters(categoriesFilterOptions));
    dispatch(addFilters(categoriesFilterOptions.filter(cfo=>cfo.selected)))

    /* filter params */
    /* todo: add filters to url */
   /* const filtersParam: string = filters.join(',');
    if (filtersParam.length > 0) {
      searchParams.set(Constants.FILTERS_KEY, filtersParam);
    }*/

    /* Dates */
    const searchDates: ISearchDate[] = [];
    for (let i=0; i<dates.length; i++) {
      const date: TAdvSearchDate = dates[i];
      if (date.start && date.end) {
        searchDates.push({
            field:date.field,
            from: date.start,
            to: date.end
        })
      }
    }
    dispatch(setSearchDates(searchDates));

    /* date params */
    if (searchDates.length > 0) {
      const datesParams: string = searchDates.map((date:ISearchDate) =>{
        return date.field + ',' + date.from + ',' + date.to
      }).join(';');
      searchParams.set(Constants.DATES_KEY, datesParams);
    } else {
      searchParams.delete(Constants.DATES_KEY);
    }

    window.history.replaceState(null, '', "?" + searchParams.toString());
  }

  const hasTerm = ():boolean => {
    return constraints.find((constraint: TAdvConstraint) => (constraint.term && (constraint.term.length > 0))) !== undefined;
  }

  const toggleCategory = (id: string): void => {
    const index: number = categoriesFilterOptions.findIndex(cfo=>cfo.id === id);
    if (index !== -1) {
      const categoriesCln: TFilterOption[] = [...categoriesFilterOptions];
      const filterOption: TFilterOption = {...categoriesCln[index]}
      filterOption.selected = !filterOption.selected;
      categoriesCln[index] = filterOption;
      setCategoriesFilterOptions(categoriesCln);
    }

    setDirty(true);
  }

  const removeConstraintLine = (line: number): void => {
    const constraintsCln: TAdvConstraint[] = [...constraints];
    constraintsCln.splice(line,1);
    setConstraints(constraintsCln);
  }

  const buildConstraint = (line: number):JSX.Element => {
    return (
      <ConstraintDropdownContext.Provider
        key={'cs' + line}
        value={{line: line, updateConstraints: updateConstraints}}
      >
        <Row className={'constraint-row mb-lg-3'}>
          {/* first line not include operator */}
          <Col className={'col-1'}>{(line > 0) &&
            <ConstraintDropdown
              constraint={Constants.OPERATOR}
              selected={constraints[line].operator.label || ''}
            />}
          </Col>

          <Col className={(isDesktop?'col-3':'')}>
            <ConstraintDropdown
              constraint={Constants.FIELD}
              selected={constraints[line].field.label}
            />
          </Col>

          <Col className={(isDesktop?'col-2-5':'')}>
            <ConstraintDropdown
              constraint={Constants.MATCH}
              selected={constraints[line].match.label || ''}
            />
          </Col>

          <Col>
            <InputGroup>
              <Form.Control
                className={'border-0 rounded px-2 ms-1'}
                type={'text'}
                aria-label={'term'}
                aria-describedby={'term-input'}
                placeholder={'Enter a search term'}
                value={constraints[line].term || ''}
                onChange={e=> updateConstraints(line, Constants.TERM, insertDBFields.insertField(e.target.value).str)}
              />
              <div className={'underline'}/>
            </InputGroup>
          </Col>

          <div className={'col-min'}>
           {(line > 0) && <Button
             className={'clear-btn btn-transparent border-0 px-1 py-0 '}
             onClick={e=> removeConstraintLine(line)}
             >
              <i className={'bi bi-x text-black'}/>
             </Button>}
          </div>
        </Row>
      </ConstraintDropdownContext.Provider>
    )
  }

  return (
    <Container className={'search-advanced border rounded bg-white px-0 pb-5 mb-5 position-relative'}>
      {/* Search button */}
      <div className={'adv-search-btn position-absolute'}>
        <SearchButton
          disabled={!hasTerm()}
          handleSubmit={e=> performQuery(e)}/>
      </div>

      {/* Categories */}
      <Row className={'categories border-bottom m-0 pb-lg-3 pt-3'}>
        <Col lg={'auto'}><strong>Search for:</strong></Col>
        <Col>
          <Form>
            {categoriesFilterOptions.map((filterOption: TFilterOption) => {
              return  (
                <Form.Check
                  className={'checkbox text-nowrap me-5'}
                  inline
                  key={Utils.convertToKeyOrId(filterOption.id)}
                  type={'checkbox'}
                  checked={filterOption.selected}
                  onChange={() => toggleCategory(filterOption.id)}
                  label={<span role="button" onClick={() => toggleCategory(filterOption.id)}>{filterOption.label}</span>}
                />
              )})
            }
          </Form>
        </Col>
      </Row>

      {/* Constraints */}
      <Row className={'px-3 py-4 mt-lg-2'}>
        <Col lg={9} className={'pe-1'}>
          <h6><strong>Build your search:</strong></h6>

          {/* Constraint dropdowns */}
          {constraints.map((constraint:any, index: number) => buildConstraint(index))}

          {/* Add new constraint line */}
          <div className={'mt-2'}>
            <Button
              variant={'light text-primary border mb-3 mb-lg-0'}
              onClick={()=>addNewLine()}
            >
              <i className={'bi bi-plus me-1'}/>
              Add New Line
            </Button>

            {/* Clear All */}
            {dirty && <Button
              className={'py-1 ms-2 border pe-4 align-top'}
              variant={'light'}
              onClick={()=>clear()}>
                <i className={'bi bi-x me-1'}/>
                Reset
              </Button>}
          </div>
        </Col>

        {/* Date Ranges */}
        <Col lg={3} className={'date-ranges pe-3 mt-3 mt-lg-0'}>
          <h6 className={'mb-0'}><strong>Date Ranges {/*<i className={'ms-1 bi bi-info-circle text-primary'}/>*/}</strong></h6>

          {/* Date pickers - some dependent on industries */}
          {dates.map((picker: TAdvSearchDate) => ((picker.industry === Constants.ALL_INDUSTRIES) ||
                  (picker.industry === searchState.industry)) &&
            <DatePicker
              key={picker.id}
              data={{...picker}}
              updateDates={updateDates}
              closePickers={closePickers}
              setClosePickers={setClosePickers}
            />)}
        </Col>
      </Row>

      <Button
        className={'text-primary w-100 border-top position-absolute bottom-0'}
        variant={'light'}
        disabled={!dirty}
        onClick={e=>performQuery(e)}
      >
        Show Results
      </Button>

    </Container>
  )
}
export default SearchAdvanced;
